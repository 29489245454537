import { Advertiser_Login_EP } from "../EndPoints";
import MainApi from "../MainApi";

export const Advertiserlogin = (data) => {
  return MainApi.post(`${Advertiser_Login_EP}/advertiserLogin`, data);
};

export const Advertiserforgetpassword = (data) => {
  return MainApi.post(`${Advertiser_Login_EP}/sendMailOtp`, data);
};

export const Advertiserpasswordupdate = (data) => {
  return MainApi.put(`${Advertiser_Login_EP}/updateForgotPassword`, data);
};
