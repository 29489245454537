import React from "react";
import "./AboutUs_Main.css";
import lov from "../AboutUs/AboutAssests/images/pixteamlove.jpg";
import vision from "../AboutUs/AboutAssests/images/teamvison.jpg";

const AboutUs_Message_1 = () => {
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-lg-5 col-md-12 col-12">
            <h1 className="fw-bolder mt-3 ">About Us</h1>
            <p className="mt-4 fs-5 ">
              Pixclick is the online visibility management and content marketing
              SaaS platform.
            </p>
            <div>
              <h1 className="fw-bold mt-5">Our message</h1>
              <p className="fs-5 mt-3">
                The best marketers enjoy and love what they do. Pixclick ensures
                marketing professionals always have time for creativity and
                experiments while we take care of data. Pixclick is the only
                software which enables marketing professionals to build, manage,
                and measure campaigns across all channels to improve their
                online visibility. Pixclick is your digital team member—your
                analytics buddy, your mentor, your safety net, and a compass to
                new markets
              </p>
            </div>
          </div>
          <div className="col-lg-1 col-md-12 col-12"></div>
          <div className="col-lg-6 col-md-12 col-12">
            <img
              src={lov}
              alt=""
              className="img-fluid rounded-5 shadow-lg animated_image_about mt-5"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs_Message_1;
