import { Advertiser_PostDetails_EP } from "../EndPoints";
import MainApi from "../MainApi";

export const Advertisercreatepost = (data) => {
  return MainApi.post(`${Advertiser_PostDetails_EP}/`, data);
};

export const Advertiserallposts = (data) => {
  return MainApi.get(
    `${Advertiser_PostDetails_EP}/getSingleAdvertiserPostedUrl`,
    {
      params: { advertiserId: data },
    }
  );
};

export const Getsingleadvertiserpost = (data) => {
  return MainApi.get(`${Advertiser_PostDetails_EP}/getSingleAdvertiserPostedUrl`, {
    params: { advertiserId: data },
  }); 
};

export const Advertiserupdatepost = (data) => {
  return MainApi.put(`${Advertiser_PostDetails_EP}/`, data);
};

export const Advertiserdeletepost = ({ _id, advertiserId }) => {
  return MainApi.delete(`${Advertiser_PostDetails_EP}/`, {
    data: { _id, advertiserId },
  });
};
