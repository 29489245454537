import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Featuresintro1 from "../../Pages/Features/Featuresintro1";
import FeaturesCards from "../../Pages/Features/FeaturesCards";
import FeaturesTrialcard from "../../Pages/Features/FeaturesTrialcard";
import FeaturesTools from "../../Pages/Features/FeaturesTools";

const Features_Main = () => {
  return (
    <>
      <Header />
      <div>
        <Featuresintro1 />
        <FeaturesCards />
        <br />
        <br />
        <FeaturesTrialcard />
        <br />
        <br />
        <FeaturesTools />
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Features_Main;
