import React, { useState } from "react";
import "./Home_Main.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";

const Home_TabComponents_04 = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const openTab = (tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(tabName).style.display = "block";
    setActiveTab(tabName);
    document.getElementById(tabName + "-tab").classList.add("active");
  };

  // This useEffect ensures that when the component mounts,
  // Tab1 is displayed and marked as active.
  React.useEffect(() => {
    openTab("Tab1");
  }, []);

  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 home_h2_tab_csss_mylol ">
            <h2>Here's why marketers ❤️ Pixclick</h2>
            <br />
            <br />
            <div>
              {/* top button ------------------------------------------------content for reference */}
              <div className="col-12 change_scroll_content pt-3">
                <div className="wholecontainertab d-flex justify-content-between ">

                  {/* Tab1 */}
                  <span className="mytab_123">
                    <button
                      id="Tab1-tab"
                      className={`tablinks ${
                        activeTab === "Tab1" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab1")}
                      style={{
                        backgroundColor: activeTab === "Tab1" ? "black" : "",
                      }}
                    >
                      <span className="text-white px-2">Marketing</span>
                    </button>
                  </span>

                  {/* Tab2 */}
                  <span className="mytab_123">
                    <button
                      id="Tab2-tab"
                      className={`tablinks ${
                        activeTab === "Tab2" ? "active" : ""
                        
                      }`}
                      onClick={() => openTab("Tab2")}
                      style={{
                        backgroundColor: activeTab === "Tab2" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">SEO</span>
                    </button>
                  </span>

                  {/*  */}
                  <span className="mytab_123">
                    <button
                      id="Tab3-tab"
                      className={`tablinks ${
                        activeTab === "Tab3" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab3")}
                      style={{
                        backgroundColor: activeTab === "Tab3" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">Content</span>
                    </button>
                  </span>
                  <span className="mytab_123">
                    <button
                      id="Tab4-tab"
                      className={`tablinks ${
                        activeTab === "Tab4" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab4")}
                      style={{
                        backgroundColor: activeTab === "Tab4" ? "black" : "",
                      }}
                    >
                      <span className="text-white  "> Market research</span>
                    </button>
                  </span>
                  <span className="mytab_123">
                    <button
                      id="Tab5-tab"
                      className={`tablinks ${
                        activeTab === "Tab5" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab5")}
                      style={{
                        backgroundColor: activeTab === "Tab5" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">Advertisting</span>
                    </button>
                  </span>
                  <span className="mytab_123">
                    <button
                      id="Tab6-tab"
                      className={`tablinks ${
                        activeTab === "Tab6" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab6")}
                      style={{
                        backgroundColor: activeTab === "Tab6" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">Social Media</span>
                    </button>
                  </span>
                </div>
              </div>

              {/* tab-content */}

              {/* tab1 */}
              <div>
                <div id="Tab1" className="tabcontent">
                  <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner pt-5">
                      <div className="carousel-item active">
                        <div className=" tabcontentcardcarasoulbodycolor1 change_card_stucture w-100 mb-3 ">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5 "
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">1/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample"
                                class="custom-right-arrow-btn"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 px-5  text-center fw-bolder">
                              "I've been using Pixclick for 7+ years now and
                              heavily rely on it to help me move whatever site
                              I'm working on top of Google".
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Himani_Kankaria.fd7493655652.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              <h3 className="fs-6 fw-bold">
                                Casey Camilleri Marx
                              </h3>
                              <p className="text-secondary">
                                Digital Marketing Director, Nlyte Software
                              </p>
                              <br />
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor1 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">2/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "What I appreciate most about Pixclick is that it
                              covers all areas of search with its tools, so i
                              can have a 360-degree view of my projects."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Esther_Gimenez.f866999bd566.jpg"
                              />
                            </div>
                            <div className="text-center">
                              <h3 className="fs-6 fw-bold mt-4">
                                Esther Gimenez
                              </h3>
                              <p className="text-secondary">
                                Senior SEO & UX Consultant, Annalect, Omnicorm
                                Media Group
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor1 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">3/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "If you ask an agency to deliver 10X organic
                              search growth through content, they will assign a
                              large team to your project. Our content team, in
                              contrast, runs activities with Pixclick without
                              having an army of people specified in SEO."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Fabrizio_Ballarini.dfe2de748d46.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold">
                                Fabrizio Ballarini
                              </h3>
                              <p className="text-secondary">
                                Responsible for SEO, TransferWise
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor1 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">4/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "Our overall organic traffic increased sevenfold
                              with blog traffic making up over 75% of the
                              visits! Semrush allowed us to optimize and run our
                              content strategy without needing an army of SEO
                              experts."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Brian_Dean.4aa07de0765a.jpg"
                              />
                            </div>

                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold mt-3">Brian Dean</h3>
                              <p className="text-secondary">
                                Founder, Backlinko
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample"
                      data-bs-slide="prev"
                    >
                      <span
                        // className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample"
                      data-bs-slide="next"
                    >
                      <span
                        // className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>

                {/* tab2 */}
                <div id="Tab2" className="tabcontent">
                  <div id="carouselExample1" className="carousel slide">
                    <div className="carousel-inner pt-5">
                      <div className="carousel-item active">
                        <div className=" tabcontentcardcarasoulbodycolor2  change_card_stucture w-100 mb-3 ">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">1/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample1"
                                class="custom-right-arrow-btn"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 px-5  text-center fw-bold">
                              "We chose Pixclick because the keyword tracking was much more accurate than our previous tools and then the interface was very intutitive.".
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                            <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Esther_Gimenez.f866999bd566.jpg"
                              />
                             
                            </div>
                            <div className="text-center mt-4">
                              <h3 className="fs-6 fw-bold">
                                Casey Camilleri Marx
                              </h3>
                              <p className="text-secondary">
                                Digital Marketing Director, Nlyte Software
                              </p>
                              <br />
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor2 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">2/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "There is a direct correlation between using Pixclick, being focused on SEO, and then Sheer growth that We've had . Our orgranic traffic was up by 230% in 2019. Literally every day we are getting the highest traffic ever on our Website."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                            <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Casey_Camilleri_Marx.335cc28f69cb.webp"
                              />
                            </div>
                            <div className="text-center">
                              <h3 className="fs-6 fw-bold mt-4">
                                Esther Gimenez
                              </h3>
                              <p className="text-secondary">
                                Senior SEO & UX Consultant, Annalect, Omnicorm
                                Media Group
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor2 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">3/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                             "With the help of Pixclick, we emplowere every single marketing team within the university to double their like we did for the central sites."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Fabrizio_Ballarini.dfe2de748d46.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold">
                                Fabrizio Ballarini
                              </h3>
                              <p className="text-secondary">
                                Responsible for SEO, TransferWise
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor2 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">4/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample1"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                             "I've never seen a local dental practice in the world ranking nationally. Because of the tool. Simple as that . And I Still don't ise even 50% of it."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Brian_Dean.4aa07de0765a.jpg"
                              />
                            </div>

                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold mt-3">Brian Dean</h3>
                              <p className="text-secondary">
                                Founder, Backlinko
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample1"
                      data-bs-slide="prev"
                    >
                      <span
                        // className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample1"
                      data-bs-slide="next"
                    >
                      <span
                        // className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>

                {/* Tab3 */}
                <div id="Tab3" className="tabcontent">
                  <div id="carouselExample2" className="carousel slide">
                    <div className="carousel-inner pt-5">
                      <div className="carousel-item active">
                        <div className=" tabcontentcardcarasoulbodycolor3  change_card_stucture w-100 mb-3 ">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">1/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample2"
                                class="custom-right-arrow-btn"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 px-5  text-center fw-bold">
                              "Our overall organic traffic increased sevenfold, with blog traffic making up over 75% of the vistis ! Pixclick allowed us to optimize and run our content strategy without needing an army of SEO experts ."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Barbora_Jassova.c7f3ffbab758.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              <h3 className="fs-6 fw-bold">
                                Barbora Jassova 
                              </h3>
                              <p className="text-secondary">
                                Content Strategist, Landbot 
                              </p>
                              <br />
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" tabcontentcardcarasoulbodycolor3 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">2/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "What I appreciate most about Pixclick is that it
                              covers all areas of search with its tools, so i
                              can have a 360-degree view of my projects."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Mesmer_Doue.41fd340e9aac.jpg"
                              />
                            </div>
                            <div className="text-center">
                              <h3 className="fs-6 fw-bold mt-4">
                                Mesmer Doue
                              </h3>
                              <p className="text-secondary">
                                Digital Marketing Consultant, Blue Lance
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="tabcontentcardcarasoulbodycolor3 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">3/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "Pixclick writing Assistant is one of a kind. I was amazed by the feedback and recommendations it provided me to improve my SEO, text readability, originality, and tone of voice."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Idan_Segal.9d2bde11a9dc.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold">
                                Idan Segal
                              </h3>
                              <p className="text-secondary">
                                Organic Growth Lead, Wix
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="tabcontentcardcarasoulbodycolor3 change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">4/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample2"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "By using Pixclick, my team saves a lot of time by working on the right content and in a more data driven way. Pixclick is my right hand for many tasks, it helps me and my team to stategize."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Ilyas_Teker.c8b832b4dfe8.jpg"
                              />
                            </div>

                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold mt-3">LLyas Tekar</h3>
                              <p className="">
                                Founder,  SEO Consultant, Mosanta
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample2"
                      data-bs-slide="prev"
                    >
                      <span
                        // className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample2"
                      data-bs-slide="next"
                    >
                      <span
                        // className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>

                {/* tab4 */}
                <div id="Tab4" className="tabcontent">
                  <div id="carouselExample3" className="carousel slide">
                    <div className="carousel-inner pt-5">
                      <div className="carousel-item active">
                        <div className=" bg-warning  change_card_stucture w-100 mb-3 ">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">1/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample3"
                                class="custom-right-arrow-btn"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 px-5  text-center fw-bold">
                              "When you are at the table with a general manager, they just want to know the market share. Market Explorer is the perfect tool to quicky show what our brand's role and classification inside."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Emanuele_Arosio.c8ef942f1b68.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              <h3 className="fs-6 fw-bold">
                                Emanuele Arosia 
                              </h3>
                              <p className="text-secondary">
                                Head of Global SEO, Triboo Group
                              </p>
                              <br />
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" bg-warning change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">2/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "My favorite part about Pixclick is the power it has for competitive intelligence. It makes it easier to make saavy advertising decisions ."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Dignorah_Altamiranda.6d5cfce51b92.jpg"
                              />
                            </div>
                            <div className="text-center">
                              <h3 className="fs-6 fw-bold mt-4">
                                Dignorah Altamiranda
                              </h3>
                              <p className="text-secondary">
                              Marketing Team Lead, Avature
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="bg-warning change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">3/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "I love the feature that you in Our content team, in
                              contrast, runs activities with Pixclick without
                              having an army of people specified in SEO."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Paul_Meluzzo.61e61cd9ca28.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold">
                                Paul Meluzzo
                              </h3>
                              <p className="text-secondary">
                                Creative Director , Omnisalash Visual
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="bg-warning change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">4/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample3"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "Pixclick has a pivotal in helping me better understand the competitive landspace of my ad compaigns. I had been able to closure the gaps betweeen my oragnic and paid efforts thus distruting my budget and tactics in a comprehensive manner."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Brian_Dean.4aa07de0765a.jpg"
                              />
                            </div>

                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold mt-3">Brian Dean</h3>
                              <p className="text-secondary">
                                Founder, Backlinko
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample3"
                      data-bs-slide="prev"
                    >
                      <span
                        // className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample3"
                      data-bs-slide="next"
                    >
                      <span
                        // className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>

                {/* tab 5 */}
                <div id="Tab5" className="tabcontent">
                  <div id="carouselExample4" className="carousel slide">
                    <div className="carousel-inner pt-5">
                      <div className="carousel-item active">
                        <div className=" bg-light  change_card_stucture w-100 mb-3 ">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">1/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample4"
                                class="custom-right-arrow-btn"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 px-5  text-center fw-bold">
                              "I've been using Pixclick for 7+ years now and
                              heavily rely on it to help me move whatever site
                              I'm working on top of Google".
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Casey_Camilleri_Marx.335cc28f69cb.webp"
                              />
                            </div>
                            <div className="text-center mt-4">
                              <h3 className="fs-6 fw-bold">
                                Casey Camilleri Marx
                              </h3>
                              <p className="text-secondary">
                                Digital Marketing Director, Nlyte Software
                              </p>
                              <br />
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" bg-light change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">2/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "What I appreciate most about Pixclick is that it
                              covers all areas of search with its tools, so i
                              can have a 360-degree view of my projects."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Esther_Gimenez.f866999bd566.jpg"
                              />
                            </div>
                            <div className="text-center">
                              <h3 className="fs-6 fw-bold mt-4">
                                Esther Gimenez
                              </h3>
                              <p className="text-secondary">
                                Senior SEO & UX Consultant, Annalect, Omnicorm
                                Media Group
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="bg-light change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">3/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "If you ask an agency to deliver 10X organic
                              search growth through content, they will assign a
                              large team to your project. Our content team, in
                              contrast, runs activities with Pixclick without
                              having an army of people specified in SEO."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Fabrizio_Ballarini.dfe2de748d46.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold">
                                Fabrizio Ballarini
                              </h3>
                              <p className="text-secondary">
                                Responsible for SEO, TransferWise
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="bg-light change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">4/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample4"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "Our overall organic traffic increased sevenfold
                              with blog traffic making up over 75% of the
                              visits! Semrush allowed us to optimize and run our
                              content strategy without needing an army of SEO
                              experts."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Brian_Dean.4aa07de0765a.jpg"
                              />
                            </div>

                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold mt-3">Brian Dean</h3>
                              <p className="text-secondary">
                                Founder, Backlinko
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample4"
                      data-bs-slide="prev"
                    >
                      <span
                        // className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample4"
                      data-bs-slide="next"
                    >
                      <span
                        // className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>

                {/* tab 6 */}
                <div id="Tab6" className="tabcontent">
                  <div id="carouselExample5" className="carousel slide">
                    <div className="carousel-inner pt-5">
                      <div className="carousel-item active">
                        <div className=" bg-success  change_card_stucture w-100 mb-3 ">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">1/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample5"
                                class="custom-right-arrow-btn"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 px-5  text-center fw-bold">
                             "Pixclick social media ToolKit is the best in the business. I'm using this tool to great effect and it has helped to increase my visibility on Social Media. "
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Casey_Camilleri_Marx.335cc28f69cb.webp"
                              />
                            </div>
                            <div className="text-center mt-4">
                              <h3 className="fs-6 fw-bold">
                                Casey Camilleri Marx
                              </h3>
                              <p className="text-secondary">
                                Digital Marketing Director, Nlyte Software
                              </p>
                              <br />
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className=" bg-success change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">2/4</span>
                              <button
                                type="button"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                             "I've been using Pixclick for the past 5 years now especially for SEO, content writing, and now even for Social marketing."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Esther_Gimenez.f866999bd566.jpg"
                              />
                            </div>
                            <div className="text-center">
                              <h3 className="fs-6 fw-bold mt-4">
                                Esther Gimenez
                              </h3>
                              <p className="text-secondary">
                                Senior SEO & UX Consultant, Annalect, Omnicorm
                                Media Group
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="bg-success change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">3/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                             "All through iy's not a core function of Pixclick i really like thier Social media posting tool - I would give that a try."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Fabrizio_Ballarini.dfe2de748d46.jpg"
                              />
                            </div>
                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold">
                                Fabrizio Ballarini
                              </h3>
                              <p className="text-secondary">
                                Responsible for SEO, TransferWise
                              </p>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="bg-success change_card_stucture w-100 mb-3">
                          <div className="card-body propicconttext">
                            <h5 className="card-title text-center change_font_cont_bt">
                              <button
                                type="button"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="prev"
                              >
                                <FaLongArrowAltLeft />
                              </button>
                              <span className="px-2">4/4</span>
                              <button
                                type="button"
                                className="mt-5"
                                data-bs-target="#carouselExample5"
                                data-bs-slide="next"
                              >
                                <FaLongArrowAltRight />
                              </button>
                            </h5>
                            <h4 className=" p-5 text-center fw-bold">
                              "Our overall organic traffic increased sevenfold
                              with blog traffic making up over 75% of the
                              visits! Semrush allowed us to optimize and run our
                              content strategy without needing an army of SEO
                              experts."
                            </h4>
                            <div className="d-flex justify-content-center casudvjhvd">
                              <img
                                className="img-fluid imagesizereduce"
                                src="https://cdn.semrush.com/static/index/testimonials/Brian_Dean.4aa07de0765a.jpg"
                              />
                            </div>

                            <div className="text-center mt-4">
                              {" "}
                              <h3 className="fs-6 fw-bold mt-3">Brian Dean</h3>
                              <p className="text-secondary">
                                Founder, Backlinko
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample5"
                      data-bs-slide="prev"
                    >
                      <span
                        // className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample5"
                      data-bs-slide="next"
                    >
                      <span
                        // className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ...more tabs if needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home_TabComponents_04;
