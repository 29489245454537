import React, { useEffect } from "react";
import "./Privacy_Policy.css";
import $ from "jquery";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Privacy_Policy = () => {
  useEffect(() => {
    $(".footer-back-to-top").click(function (e) {
      e.preventDefault(); 
      $("html, body").animate({ scrollTop: 0 }, 1000);
    });
  }, []);

  return (
    <div className="PRIVACY_POLICY_BG text-white">
      <Header />
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-12">
            <span className="fs-3 fw-bold FCP">PIXCLICK PRIVACY POLICY</span>
          </div>
          <div className="col-12 pt-5">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              INTRODUCTION:
            </span>
            <p className="pt-3">
              We at Pixclick Private Limited are committed to safeguarding the
              privacy of our website and/or mobile application visitors; this
              policy sets out how we will treat your personal information.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              A. WHAT DO WE COLLECT?
            </span>
            <p className="pt-3">
              We may collect, store, and use the following kinds of personal
              data upon user consent:
            </p>
            <p>Registration and Profile Information:</p>
            <p>
              When you sign up for an account or use our services, we will ask
              you for the following information:
            </p>
            <p>a) Profile Photo</p>
            <p>b) Your Name</p>
            <p>c) Username</p>
            <p>d) Mobile Number</p>
            <p>e) Email ID</p>
            <p>f) DOB</p>
            <p>g) Gender</p>
            <p>
              h) Any other relevant information as required from time to time
              collected upon user consent. Sensitive information is stored in
              encrypted format.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Communications:
            </span>
            <p className="pt-3">
              If you contact us directly, we may receive additional information
              about you. For example, when you contact us for support, we will
              receive your name, email address, phone number and contents of
              your support issue along with any other information you choose to
              provide.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Device Information:
            </span>
            <p className="pt-3">
              We receive information about the device and software you use to
              access our Services, including IP address, type of Web Browser,
              Operating System Version, Phone Carrier and Manufacturer,
              application installations, device identifiers and push
              notification tokens.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Usage Information:
            </span>
            <p className="pt-3">
              To help us understand how you use our Services and to help us
              improve them, we automatically receive information about your
              interactions with them, like the pages or other content you view.
              The information you upload, the length of time you spend on a
              page, objects such as hyperlinks you click on, and the dates and
              times of your visits.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Contacts Information:
            </span>
            <p className="pt-3">
              The users' contact information is collected only for the following
              purpose - to enable users to communicate and share content with
              their friends (who are in contact only). Our app functions in a
              way to build friends network, who are in the contacts.
            </p>
            <p className="pt-3">
              This will provide a good user experience for the user, where they
              are easily able to connect with their contacts. The above data is
              collected only after every individual user's approval. Without the
              user's consent, our app won't collect any contact data from the
              user.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Post Upload:
            </span>
            <p className="pt-3">
              When you use our services, you trust us with your information to
              post photos, videos, and locations. While uploading the post, we
              are collecting post related images, videos, and locations. Users
              can change access to private or public posts.
            </p>
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              B. HOW DO WE USE YOUR PERSONAL DATA?
            </span>
            <p className="pt-3">
              Personal Data submitted on our Platform will be used for the
              purposes specified in this Privacy Policy or in relevant parts of
              our Website/ Mobile Application.
            </p>
            <p className="pt-3">We may use your Personal Information:</p>
            <p>
              <ul className="d-flex flex-column gap-1">
                <li>
                  To provide, maintain, improve, and enhance our Services;
                </li>
                <li>
                  To understand and analyse how you use our Services and develop
                  new products, services, features, and functionality;{" "}
                </li>
                <li>
                  To communicate with you, provide you with updates and other
                  information relating to our Services, provide information that
                  you request, respond to comments and questions, and otherwise
                  provide customer support;{" "}
                </li>
                <li>
                  To generate de-identified or aggregated data for our business
                  purposes;
                </li>
                <li>To send you to push notifications and text messages;</li>
                <li>
                  To find and prevent fraud, and respond to trust and safety
                  issues that may arise;
                </li>
                <li>
                  For compliance purposes, including enforcing our Terms of
                  Service or other legal rights, as may be required by
                  applicable laws and regulations, or as requested by any
                  judicial process or governmental agency; and
                </li>
                <li>
                  For other purposes for which we provide specific notice at the
                  time the information is collected.
                </li>
              </ul>
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              C. HOW DO WE SHARE THE INFORMATION WE COLLECT?
            </span>
            <p className="pt-3">
              We do not share or otherwise disclose the information we collect
              from or about you except as described below or otherwise disclosed
              to you at the time of the collection:
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              User Content:
            </span>
            <p className="pt-3">
              Our services are social networking services in which you can view
              and share content. Your name, user name and other profile
              information will be viewable by other users. The content you share
              on the services will be displayed on the services and viewable by
              other users to whom you have given access to view such content.
              Our platform will not be responsible for the other users' usage of
              your content, so you should carefully consider whether and what to
              share and how you identify yourself on the platform. Pixalive is a
              social network app, where users can post and share their text,
              images, and videos with their friends & followers through the
              platform's content feed. Our app enables the user to build their
              friends & followers' networks and make them view others' content &
              share the user's own content (text, images & videos), only after
              their consent and approval. In this way, the app functions as a
              social networking platform for the end-users. Without the user's
              consent, our app will not collect any content data from the users.
              This content data will be stored with us, and we assure you that
              we will not share this data with any other 3rd parties.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Analytics Partners:
            </span>
            <p className="pt-3">
              We may use analytics services such as Google Analytics to collect
              and process certain analytics data. These services may also
              collect information about your use of other websites, apps, and
              online resources.
            </p>
            <p className="pt-3">As required by Law and Similar Disclosures-</p>

            <p className="pt-3  ">
              <ol className="d-flex flex-column gap-1 text-decoration-unstyled">
                <li>
                  Comply with law enforcement requests and legal processes, such
                  as a Court Order or subpoena;
                </li>
                <li>Respond to your requests; or</li>
                <li>
                  Protect your, our, or others’ rights, property, or safety.
                </li>
                <li>
                  or the avoidance of doubt, the disclosure of your information
                  may occur if you send any objectionable content on or through
                  our services.
                </li>
                <li>Merger, Sale, or Other Asset Transfer</li>
                <li>
                  We may transfer your information to service providers,
                  advisors, potential transactional partners, or other third
                  parties in connection with the consideration, negotiation, or
                  completion of a corporate transaction in which we are acquired
                  by or merged with another company or we sell, liquidate, or
                  transfer all or a portion of our assets.
                </li>
              </ol>
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="fs-5 fw-bold text-decoration-underline">
              {" "}
              Consent:
            </span>
            <p className="pt-3">
              We may also disclose your information with your permission.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              D. WHERE WE STORE YOUR PERSONAL INFORMATION
            </span>
            <p className="pt-3">
              We store your data with Amazon Web Services, on its servers
              located in India and abroad. The Web Service implement the
              requisite Security measures to protect the loss, misuse and
              alteration of the information, details of which are available at
              Amazon Web Services.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              E. YOUR CHOICES
            </span>
            <p className="pt-3">Sharing Preferences</p>
            <p className="pt-0">
              We provide you with settings to allow you to set your sharing
              preferences for content you share on our services. Certain
              information may always be publicly available to others, and other
              information is publicly viewable, you can adjust the settings in
              your account.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              Additional Information:
            </span>
            <p className="pt-3">
              Additional information regarding privacy settings can be found
              here: support@pixclick.me
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              Do Not Track:
            </span>
            <p className="pt-3">
              There is no accepted standard on how to respond to Do Not Track
              signals, and we do not respond to such signals. If you choose not
              to provide us with the information we collect, some features of
              our services may not work as intended.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              F. THIRD PARTIES
            </span>
            <p className="pt-3">
              Our Services may contain links to other websites, apps, products,
              or services that we do not own or operate. We are not responsible
              for the privacy practices of these third parties. We encourage you
              to read their privacy policies before providing any information to
              them.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              G. SECURITY
            </span>
            <p className="pt-3">
              We make reasonable efforts to protect your information by using
              physical and electronic safeguards designed to improve the
              security of the information we maintain. However, as no electronic
              transmission or storage of information can be entirely secure, we
              can make no guarantees as to the security or privacy of your
              information.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              H. CHILDREN’S PRIVACY
            </span>
            <p className="pt-3">
              We do not knowingly collect, maintain, or use personal information
              from children under 13 years of age, and no part of our Services
              are directed to children. If you learn that a child has provided
              us with personal information in violation of this Privacy Policy,
              you may alert us at support@pixalive.me
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              I. UPDATE YOUR INFORMATION OR POSE A QUESTION
            </span>
            <p className="pt-3">
              You can update your account and profile information through your
              profile settings. If you have questions about your privacy on our
              Services or this privacy policy, please contact us at
              support@pixalive.me
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              J. CHANGES TO THIS PRIVACY POLICY
            </span>
            <p className="pt-3">
              We will post any adjustments to the Privacy Policy on this page,
              and the revised version will be effective when it is posted. If we
              materially change the ways in which we use or share personal
              information previously collected from you through our Services, we
              will attempt to notify you through our Services, by email, or
              other means.
            </p>
          </div>

          <div className="col-12 pt-3">
            <span className="pt-3 fs-5 fw-bold text-decoration-underline">
              K. GRIEVANCE POLICY
            </span>
            <p className="pt-3">
              We at Pixclick, always thrive to deliver the best values to our
              users and give the best experience under all circumstances. To
              offer this seamlessly, we have created this Grievance policy to
              solve all our customer's grievance-related problems in a very
              efficient way. In some rare circumstances, due to random
              human-related or technical errors, chances are there for issues to
              happen for our users. To address this kind of issue, our grievance
              policy will be surely helpful for our end users.
            </p>
            <p className="pt-3">
              If the user still wants to communicate with us, they can reach us
              via help@Pixclick.me where our executives will communicate and
              clarify the user's queries and provide the right solution via
              chat/call.
            </p>
          </div>
          <div className="col-12 footer-back-to-top d-flex justify-content-end my-5 text-white BACK_TO_TOP">
            <p className="mt-5 text-black bg-white p-1 rounded d-flex align-items-center gap-2">
              Back to top
              <i class="bi bi-arrow-up-circle-fill"></i>{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy_Policy;
