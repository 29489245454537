import React, { useEffect } from "react";
import "./Home_Main.css";
import AOS from "aos";

import st1 from "./Home_Assets/images/Heart.svg";
import st2 from "./Home_Assets/images/star.svg";
import st3 from "./Home_Assets/images/greencolor.svg";

const Home_Award_02 = () => {
  useEffect(() => {
    const initializeAOS = () => {
      AOS.init();
    };
    const delay = 2000;

    const timeoutId = setTimeout(initializeAOS, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
<div className="awardwinningmediaquery">
    <div className="container  ">
      
      <div className="row text-center  ">
        <div className="my-5 py-5 fs-1 fw-bold text-center"><h1 className="fw-bold">Award-winning tools trusted <br/>by the world’s leading companies</h1></div>
        
        <div className="col-lg-4 col-md-6 col-12">
          <img
            src={st1}
            alt="Star"
            className="corner_image_Home top-right img-fluid "
            data-aos="flip-left"
          />
          <h1 className="Homeaward_icons_style_size">10M</h1>
          <p className="fw-bolder fs-5">marketing professionals</p>
          <p className="fs-4">have already used Pixclick</p>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <img
            src={st2}
            alt="Star"
            className="corner_image_Home top-right img-fluid"
            data-aos="flip-left"
          />
          <h1 className="Homeaward_icons_style_size ">21</h1>
          <p className="fw-bolder fs-5">international awards</p>
          <p className="fs-4">as best SEO Software Suite</p>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <img
            src={st3}
            alt="Star"
            className="corner_image_Home top-right img-fluid"
            data-aos="flip-left"
          />
          <h1 className="Homeaward_icons_style_size ">30%</h1>
          <p className="fw-bolder fs-5">Fortune 500 componies</p>
          <p className="fs-4 text-center">use Pixclick as their go-to <br/>marketing tool</p>
        </div>
      </div>
    </div></div>
  );
};

export default Home_Award_02;
