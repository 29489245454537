import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AboutUs_Main.css";
import Mapcardimg from "../AboutUs/AboutAssests/images/pixaliveteam.jpg";
import Mapcardimg2 from "../AboutUs/AboutAssests/images/pixteamsca3.jpg";

const AboutUs_Map_06 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: false,
    cssEase: "linear",
  };

  return (
    <div className="container-fluid mt-5">
      <div className="container">
        <Slider {...settings}>
          {/* Slide 1 */}
          <div>
            <div class="card mb-3 map_for_cardsize rounded-5">
              <div class="row g-0">
                <div class="col-lg-5 col-md-12 col-12">
                  <img
                    src={Mapcardimg}
                    class="img-fluid rounded-5 p-2  mapcardimgsizelolol"
                    alt=""
                  />
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                  <div class="card-body mt-4 ">
                    <h5 class="card-title fw-bold fs-1 ">Pixclick team </h5>
                    <p class="card-text  mt-4 ">
                      <i>
                        Pixalive Group is a diverse organization based in
                        Bengaluru, Karnataka, India, encompassing six key
                        sectors: Social Media, SaaS, IT Services, Startups,
                        Software training & Sustainability. Its multi-sector
                        presence promises significant benefits to the nation.
                      </i>
                    </p>
                    <div className="button_forfeatures1 mt-5">
                      <button type="button" class="btn  px-5 py-3 fw-bold">
                        <a href="" className="text-white">
                          Join Our Team
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div>
            <div class="card mb-3 map_for_cardsize rounded-5 bg-dark">
              <div class="row g-0">
                <div class="col-lg-5 col-md-12 col-12">
                  <img
                    src={Mapcardimg2}
                    class="img-fluid rounded-5 p-2  mapcardimgsizelolol"
                    alt=""
                  />
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                  <div class="card-body mt-4 ">
                    <h5 class="card-title fw-bold fs-1 text-white">
                      Pixclick team
                    </h5>
                    <p class="card-text  mt-4 text-white ">
                      <i>
                        Though we’ve grown from a small startup to a large
                        international company, we remain fully committed to the
                        fundamental values that got us where we are now:
                        adapting to change versus following a strict plan and by
                        placing individuals over processes. At Pixclickrush, we
                        take an agile approach where it brings the most value .
                      </i>
                    </p>
                    <div className="button_forfeatures1 mt-5">
                      <button type="button" class="btn  px-5 py-3 fw-bold">
                        <a href="" className="text-white">
                          Join Our Team
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default AboutUs_Map_06;
