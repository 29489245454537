import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import "./SignUp_Page.css";
import { useNavigate, useLocation } from "react-router-dom";
import SignUp_Page_Cover_Pix from "../../../Assets/SignUp_Page_Cover_Pix.png";
import Signup_Page_Next_Icon from "../../../Assets/Signup_Page_Next_Icon.png";
import { toast } from "react-toastify";
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from "../../../Utils/Validation";
import { saveToken } from "../../../Utils/Storage";
import { Advertiser_SignUp_API } from "../../../Api/API_List/Advertiser_SignUp_API";
import { isAuthenticated } from "../../../Utils/Auth";

const SignUp_Page_3 = () => {
  const location = useLocation();
  const { email, mobileNumber, name, companyName, password, confirmPassword } =
    location.state;

  const initialStateInputs = {
    name: name,
    mobileNumber: mobileNumber,
    email: email,
    companyName: companyName,
    password: password,
    confirmPassword: confirmPassword,
    referralCode: "",
  };

  const initialStateErrors = {
    name: { required: false, valid: false },
    mobileNumber: { required: false, valid: false },
    email: { required: false, valid: false },
    companyName: { required: false, valid: false },
    password: { required: false, valid: false },
    confirmPassword: { required: false, valid: false },
    referralCode: { required: false, valid: false },
  };

  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber.valid = true;
    }
    if (!isValidPassword(data.password)) {
      error.password.valid = true;
    }
    if (data.password !== data.confirmPassword) {
      error.confirmPassword.valid = true;
    }
    if (data.companyName === "") {
      error.companyName.required = true;
    }
    if (data.referralCode === "") {
      error.referralCode.required = true;
    }
    return error;
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });

    if (submitted) {
      const newError = handleValidation({ ...inputs, [name]: value });
      setErrors(newError);
    }

    console.log("Console result:", inputs, submitted);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    console.log("Console result:", inputs);
    setErrors(newError);
    setSubmitted(true);

    // Assuming form submission is successful without API call for now
    if (
      inputs.email &&
      inputs.mobileNumber &&
      inputs.name &&
      inputs.companyName &&
      inputs.password &&
      inputs.confirmPassword
    ) {
      Advertiser_SignUp_API(inputs)
        .then((res) => {
          const token = res?.data?.result?.token;
          const loginType = res?.data?.result?.loginType;
          const advertiserId = res?.data?.result?.advertiserDetails?._id;
          const tokenData = {
            token: token,
            loginType: loginType,
            advertiserDetails: {
              _id: advertiserId,
            },
          };
          saveToken(tokenData);
          if (isAuthenticated()) {
            navigate("/OTP_Verify", {
              state: {
                email: inputs.email,
                mobileNumber: inputs.mobileNumber,
              },
            });
            toast.success(res?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  return (
    <div>
      <div className="overflow-x-hidden overflow-y-hidden">
        <div className="row">
          <div className="col-lg-6">
            <div className="SIGNUP_LEFT_CONTAINER">
              <img
                src={SignUp_Page_Cover_Pix}
                className="img-fluid WELCOME_PAGE_COVER_PIX "
                alt="Welcome_Page_Cover_Pix"
              />
            </div>
          </div>
          <div className="col-lg-6 SIGNUP_RIGHT_SIDE_AREA">
            <span className="SIGNUP_TITLE">Sign Up</span>

            <div className="SIGNUP_FORM_CONTAINER shadow rounded-3">
              <span className="SIGNUP_STEP_TAG">Step 3/3</span>
              <div className="pt-4  d-flex flex-column gap-3">
                <div className="FINAL_STEP_CONTENT">
                  Finalize Your Account Creation in Just One More Step and
                  Unlock all the amazing features Awaiting You!
                </div>
                <div>
                  <span className="SIGNUP_TITLE_TAG">
                    Referral Code <span>(Optional)</span>
                  </span>

                  <input
                    className="SIGNUP_INPUT"
                    placeholder="Enter Your Referral Code"
                    type="text"
                    onChange={handleInput}
                    name="referralCode"
                  />
                </div>
              </div>

              <p className="LOGIN_BUTTON d-flex align-items-center justify-content-center gap-2 mt-5">
                <img
                  src={Signup_Page_Next_Icon}
                  className="img-fluid WELCOME_PAGE_PHONE_ICON"
                  alt="Welcome_Page_Phone_Icon"
                  onClick={handleSubmit}
                />
                Finish
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp_Page_3;
