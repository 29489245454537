import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Home_firstcomponents_01 from "../../Pages/Home/Home_firstcomponents_01";
// import Home_TabMessage_1 from "../../Pages/Home/Home_TabMessage_1";
import Home_customer from "../../Pages/Home/Home_customer";
import Home_Award_02 from "../../Pages/Home/Home_Award_02";
import Home_Thirdcomponents_03 from "../../Pages/Home/Home_Thirdcomponents_03";
import Home_TabComponents_04 from "../../Pages/Home/Home_TabComponents_04";
import Home_Final_05 from "../../Pages/Home/Home_Final_05 ";

const Home_Main = () => {
  return (
    <>
      <Header />
      <div>
        <Home_firstcomponents_01 /> <br />
        <Home_customer /> <br /><br />
        <Home_Award_02 />  
        <Home_Thirdcomponents_03 /> <br /><br />
        <Home_TabComponents_04 /> 
        <Home_Final_05 />
      </div>

      <Footer />
    </>
  );
};

export default Home_Main;
