import MainApi from "../MainApi";
import { Advertiser_SignUp_EP, Advertiser_Login_EP } from "../EndPoints";

export const Advertiser_SignUp_API = (data) => {
  return MainApi.post(`${Advertiser_SignUp_EP}/`, data);
};

export const Advertiser_otp_Api = (data) => {
  return MainApi.post(`${Advertiser_Login_EP}/verifyGmailOtp`, data);
};
