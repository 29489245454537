import React, { useEffect, useState } from "react";
import "./Createaccount.css";
import coin from "../../../../Assets/coin.png";
import DUMMY_PROFILE_PIC from "../../../../Assets/DUMMY_PROFILE_PIC.png";
import { BiPencil } from "react-icons/bi";
import ACCOUNT_CATEGORY from "../../../../Assets/ACCOUNT_CATEGORY_ICON.png";
import ACCOUNT_TYPE_ICON from "../../../../Assets/ACCOUNT_TYPE_ICON.png";
import ACCOUNT_HOLDER_NAME_ICON from "../../../../Assets/ACCOUNT_HOLDER_NAME_ICON.png";
import FOLLOWERS_ICON from "../../../../Assets/FOLLOWERS_ICON.png";
import FOLLOWING_ICON from "../../../../Assets/FOLLOWING_ICON.png";
import TOTAL_REVENUE_ICON from "../../../../Assets/TOTAL_REVENUE_ICON.png";
import TOTAL_COST_ICON from "../../../../Assets/TOTAL_COST_ICON.png";
import GENDER_ICON from "../../../../Assets/GENDER_ICON.png";
import SELECT_COUNTRY_ICON from "../../../../Assets/SELECT_COUNTRY_ICON.png";
import JOINED_DATE_ICON from "../../../../Assets/JOINED_DATE_ICON.png";
import { Link, useNavigate } from "react-router-dom";
import { getAdvertiserId } from "../../../../Utils/Storage";
import { create_selling_account_api } from "../../../../Api/API_List/Advertiser_Create_Selling_Account_API";
import { toast } from "react-toastify";
import { uploadFile } from "../../../../Utils/Fileupload";

const Createaccount = () => {
  let initialState = {
    advertiserId: getAdvertiserId(),
    accountLogo: "",
    name: "",
    followers: "",
    following: "",
    revenue: "",
    totalCost: "",
    category: "",
    accountType: "",
    image: "",
    subscribe: "",
    dateOfJoining: "",
    gender: "",
    country: "",
  };

  let initialStateerror = {
    accountLogo: { required: false },
    name: { required: false },
    followers: { required: false },
    following: { required: false },
    revenue: { required: false },
    totalCost: { required: false },
    category: { required: false },
    accountType: { required: false },
    image: { required: false },
    subscribe: { required: false },
    dateOfJoining: { required: false },
    gender: { required: false },
    country: { required: false },
  };

  const [account, setAccount] = useState(initialState);
  const [errors, setErrors] = useState(initialStateerror);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleValidation = (data) => {
    const error = { ...initialStateerror };
    if (data.accountLogo === "") {
      error.accountLogo.required = true;
    }
    if (data.name === "") {
      error.name.required = true;
    }
    if (data.followers === "") {
      error.followers.required = true;
    }
    if (data.following === "") {
      error.following.required = true;
    }
    if (data.revenue === "") {
      error.revenue.required = true;
    }
    if (data.totalCost === "") {
      error.totalCost.required = true;
    }
    if (data.category === "") {
      error.category.required = true;
    }
    if (data.accountType === "") {
      error.accountType.required = true;
    }
    if (data.image === "") {
      error.image.required = true;
    }
    if (data.subscribe === "") {
      error.subscribe.required = true;
    }
    if (data.dateOfJoining === "") {
      error.dateOfJoining.required = true;
    }
    if (data.gender === "") {
      error.gender.required = true;
    }
    if (data.country === "") {
      error.country.required = true;
    }
    return error;
  };

  const handleInputs = (event) => {
    setAccount({ ...account, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...account,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("file_upload");
    fileInput.click();
  };

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    const folder = "Account_Logo/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const acc_logo_image = res?.Location;
          setAccount({ ...account, image: acc_logo_image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(account);
    setErrors(newError);
    setSubmitted(true);
    // const allInputValid = Object.values(newError);
    // const valid = allInputValid.some((x) => x.required === true);
    // if (!valid) {
    create_selling_account_api(account)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        navigate("/advertisementpanel/listaccount");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
    // }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <span className="CREATE_ACC_TITLE">Create Account</span>
            {/* -------------------------ACCOUNT_LOGO_IMAGE--------------------------- */}
            <div className="col-12 d-flex justify-content-center">
              <div className="ACCOUNT_PROFILE_IMAGE">
                <img
                  src={account?.image || DUMMY_PROFILE_PIC}
                  alt="ACCOUNT_PROFILE_IMAGE"
                  name="image"
                  onClick={handleClick}
                  className="DUMMY_PROFILE_PIC"
                />
                <BiPencil className="ACC_EDIT_ICON" onClick={handleClick} />

                <input
                  name="image"
                  id="file_upload"
                  onChange={handleFileInputs}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                />
                {errors.image.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="col-12 pt-5 d-flex justify-content-end gap-3">
              <span className="COIN_PIC px-3 py-1">
                <img src={coin} alt="" />
              </span>
              <Link to="/AdvertisementPanel/Listaccount">
                <button className="btn btn-primary">View All Accounts</button>
              </Link>
            </div>
          </div>

          {/* -------------------------INPUT_FIELDS_AREA--------------------------- */}
          <div className="row">
            {/* -------------------------ACCOUNT_TYPE--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={ACCOUNT_TYPE_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Account Type
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <select
                  className="w-100"
                  name="accountType"
                  onChange={handleInputs}
                >
                  <option className="ACC_CATEGORY_OPTION" value="Facebook">
                    Facebook{" "}
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="Instagram">
                    Instagram
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="Twitter">
                    Twitter
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="YouTube">
                    YouTube
                  </option>
                </select>
                {errors.accountType.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            {/* -------------------------ACCOUNT_CATEGORY--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={ACCOUNT_CATEGORY}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Account Category
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <select
                  className="w-100"
                  name="category"
                  onChange={handleInputs}
                >
                  <option className="ACC_CATEGORY_OPTION" value="E-Commerce">
                    E-Commerce
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="automobile">
                    Automobile
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="business">
                    Business
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="education">
                    Education
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="entertainment">
                    Entertainment
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="fashion">
                    Fashion
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="fitness">
                    Fitness
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="food">
                    Food
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="gaming">
                    Gaming
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="health">
                    Health
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="learning">
                    Learning
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="sports">
                    Sports
                  </option>
                </select>
                {errors.category.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* -------------------------ACCOUNT_HOLDER_NAME--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={ACCOUNT_HOLDER_NAME_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Account{" "}
                  <span className="text-danger fw-bold">Holder Name</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="name"
                  onChange={handleInputs}
                />
                {errors.name.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            {/* -------------------------FOLLOWERS--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={FOLLOWERS_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  No. Of <span className="text-danger fw-bold">Followers</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="followers"
                  onChange={handleInputs}
                />
                {errors.followers.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* -------------------------FOLLOWING--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={FOLLOWING_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  No. Of <span className="text-danger fw-bold">Following</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="following"
                  onChange={handleInputs}
                />
                {errors.following.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            {/* -------------------------TOTAL_REVENUE--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={TOTAL_REVENUE_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Total <span className="text-danger fw-bold">Revenue</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="revenue"
                  onChange={handleInputs}
                />
                {errors.revenue.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* -------------------------TOTAL_COST--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={TOTAL_COST_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Total <span className="text-danger fw-bold">Cost</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="totalCost"
                  onChange={handleInputs}
                />
                {errors.totalCost.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            {/* -------------------------GENDER_ICON--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Select <span className="text-danger fw-bold">Gender</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <select className="w-100" name="gender" onChange={handleInputs}>
                  <option className="ACC_CATEGORY_OPTION" value="Male">
                    Male{" "}
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="Female">
                    Female
                  </option>
                  <option className="ACC_CATEGORY_OPTION" value="Transgender">
                    Transgender
                  </option>
                </select>
                {errors.gender.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* -------------------------SELECT_COUNTRY--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={SELECT_COUNTRY_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Select <span className="text-danger fw-bold">Country</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <div className="INPUT_BOX pt-1">
                  <select
                    className="w-100"
                    name="country"
                    onChange={handleInputs}
                  >
                    <option value="India">India</option>
                    <option value="Australia">Australia</option>
                    <option value="Russia">Russia</option>
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="Brazil">Brazil</option>
                    <option value="China">China</option>
                    <option value="Japan">Japan</option>
                    <option value="Germany">Germany</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="France">France</option>
                    <option value="Italy">Italy</option>
                    <option value="Spain">Spain</option>
                    <option value="Mexico">Mexico</option>
                    <option value="South Korea">South Korea</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Norway">Norway</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Austria">Austria</option>
                    <option value="Poland">Poland</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="South Africa">South Africa</option>
                  </select>
                  {errors.country.required && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
            </div>

            {/* -------------------------JOINED_DATE--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={JOINED_DATE_ICON}
                  className="CREATE_ACC_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_ACC_INPUT_FIELDS_TITLE">
                  Date of{" "}
                  <span className="text-danger fw-bold">Account Creation</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-2">
                <input
                  type="date"
                  className="w-100"
                  placeholder="Select your birthday"
                  name="dateOfJoining"
                  onChange={handleInputs}
                />
                {errors.dateOfJoining.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>

          {/* -------------------------CREATE_POST_BUTTON--------------------------- */}
          <div className="row py-3">
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="CREATE_ACC_BUTTON">
                Submit to Post New Account
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Createaccount;
