import { useEffect, useState } from "react";
import "./Header.css";
import LOGO from "../Header/Assests/images/Pixclicklogonew.jpg";
import { isAuthenticated } from "../../Utils/Auth";
import { FaBars, FaTimes, FaUser } from "react-icons/fa";
import { toast } from "react-toastify";
import { clearStorage, getAdvertiserId } from "../../Utils/Storage";
import { useNavigate } from "react-router-dom";
import { Getsingleadvertiser } from "../../Api/API_List/Advertiser";
import { IoLogOutOutline } from "react-icons/io5";

const Header = () => {
  const [isResponsive, setResponsive] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(isAuthenticated());
  const navigate = useNavigate();

  const toggleResponsive = () => {
    setResponsive(!isResponsive);
  };

  const [name, setName] = useState("");

  const Getname = () => {
    const id = getAdvertiserId();
    Getsingleadvertiser(id)
      .then((res) => {
        setLoggedIn(true);
        setName(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Getname();
  }, []);

  const handleLogout = () => {
    clearStorage();
    setLoggedIn(false);
    toast.success("You have logged out successfully.");
    navigate("/");
  };

  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg Header_Background_color">
          <div className="container-fluid">
            <div className="Logo_container">
              <a href="/">
                <img src={LOGO} className="mx-2 img-fluid" alt="" />
              </a>
            </div>

            <button
              className="navbar-toggler TOGGLEBUTTONCLASSNAME bg-dark"
              type="button"
              onClick={toggleResponsive}
              id="toggle_button"
            >
              {isResponsive ? <FaTimes /> : <FaBars />}
            </button>

            <div
              className={`collapse navbar-collapse color_chng ${
                isResponsive ? "show" : ""
              }`}
            >
              <ul className="navbar-nav fw-bold ms-auto headercolorhover Header_suma gap-4">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/">
                    HOME
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/Feed">
                    FEED
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="/Features_Main"
                  >
                    FEATURES
                  </a>
                </li>
                {isLoggedIn && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      aria-current="page"
                      href="/AdvertisementPanel"
                    >
                      Advertisement Panel
                    </a>
                  </li>
                )}
                <li className="nav-item">
                  <a className="nav-link" href="/AboutUs_Main">
                    ABOUT
                  </a>
                </li>

                {!isLoggedIn ? (
                  <>
                    <li className="nav-item">
                      <a href="/Welcome_Page">
                        <button className="btn btn-primary p-2 px-3 fw-bold">
                          Login
                        </button>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/SignUp_Page_1">
                        <button className="btn btn-dark fw-bold">Signup</button>
                      </a>
                    </li>
                  </>
                ) : (
                  <li className="nav-item dropdown mt-1 ">
                    <div className="dropdown">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle d-flex align-items-center gap-2 bg-dark text-white rounded-5"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FaUser />
                        {name?.name}
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-start" 
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <a className="dropdown-item" href="/Myprofile">
                            <FaUser size={20} /> My Profile
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/#"
                            onClick={handleLogout}
                          >
                            <IoLogOutOutline size={25} /> Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
