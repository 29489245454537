import React from "react";
import "./AboutUs_Main.css";

const AboutUs_cards_09 = () => {
  return (
    <div className="container ">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-12">
        <div className="card rounded-5 bg-dark">
        <div className="card-body p-5 px-5 py-5 cardbody_color rounded-5">
          <p className="text-center text-white fw-bold  ">
            Pixclick helps marketers and publishers optimize their websites for
            discovery by consumers using popular search engines like Google,
            Bing, and Yahoo.{" "}
          </p>
          <br />
          <p className="text-center text-white fw-bold ">WSJ</p>
        </div>
        

      </div>
      <br />
      
        </div>
      
      <br />
      
      <div className="col-lg-6 col-md-12 col-12 ">
      <div className="card rounded-5 bg-dark">
        <div className="card-body p-5 px-5 py-5 bg-black cardbody_color2 rounded-5">
          <p className="text-center fw-bold  text-white  ">
            Pixclick provides a SaaS platform that helps companies improve
            their online marketing efforts. The platform is essentially a
            ‘one-stop shop’ for search engine marketing{" "}
          </p>
          <br />
          <p className="text-center text-white ">Seeking Alpha</p>
        </div>
      </div>
      </div>
     
      <br />
      </div>
      <br />
    </div>
  );
};

export default AboutUs_cards_09;
