import React, { useState } from "react";
import "./Home_Main.css";
// import imggg1 from "./Home_Assets/images/ss1.png";
// import imggg2 from "./Home_Assets/images/ss2.png";
// import imggg3 from "./Home_Assets/images/ss3.png";
// import imggg4 from "./Home_Assets/images/ss4.png";
// import imggg5 from "./Home_Assets/images/ss5.png";
// import imggg6 from "./Home_Assets/images/ss6.png";
import { TbSettingsSearch } from "react-icons/tb";
import { TfiPencilAlt } from "react-icons/tfi";
import { MdOutlineMonitorHeart } from "react-icons/md";
import { PiSpeakerHighLight } from "react-icons/pi";
import { SlSocialGoogle } from "react-icons/sl";
import { BsPersonWorkspace } from "react-icons/bs";



const Home_TabMessage_1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleButtonClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  

  return (
    <>
      <div className="container bg-white rounded-5  shadow ">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 rounded-5 shadow-lg">
            <div
              className="
           overflow_x_scroll"
            >
              <ul className="d-flex justify-content-between qdusabhbash pt-5">
                <li>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={0}
                    className={activeTab === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label="Slide 1"
                    style={{
                      backgroundColor: activeTab === 0 ? "#fff" : "",
                      color: activeTab === 0 ? "#3f8acf" : "", 
                      borderBottom: activeTab === 0 ? "3px solid #3f8acf" : "none", 
                    }}
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="hellomediaquerylol">
                      <div className="mediaquery_immm">
                        {/* <img src={imggg1} className="img-fluid" /> */}
                        <TbSettingsSearch />
                      </div>
                      <div className="text_change_font_size ">
                        <h6 className="text-center  fw-bold ">SEO</h6>
                      </div>
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={1}
                    className={activeTab === 1 ? "active" : ""}
                    aria-label="Slide 2"
                    style={{
                      backgroundColor: activeTab === 1 ? "#fff" : "",
                      color: activeTab === 1 ? "#3f8acf" : "",
                      borderBottom: activeTab === 1 ? "3px solid #3f8acf" : "none",  
                    }}
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="mediaquery_immm">
                      {/* <img src={imggg2} className="img-fluid" /> */}
                      <TfiPencilAlt />
                    </div>
                    <div className="text_change_font_size">
                      <h6 className="text-center fw-bold">Content</h6>
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={2}
                    className={activeTab === 2 ? "active" : ""}
                    aria-label="Slide 3"
                    style={{
                      backgroundColor: activeTab === 2 ? "#fff" : "",
                      color: activeTab === 2 ? "#3f8acf" : "", 
                      borderBottom: activeTab === 2 ? "3px solid #3f8acf" : "none", 
                    }}
                    onClick={() => handleButtonClick(2)}
                  >
                    <div className="mediaquery_immm">
                      {/* <img src={imggg3} className="img-fluid" /> */}
                      <MdOutlineMonitorHeart />
                    </div>{" "}
                    <div className="text_change_font_size">
                      <h6 className="text-center fw-bold">Research</h6>
                    </div>
                  </button>{" "}
                </li>
                <li>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={3}
                    className={activeTab === 3 ? "active" : ""}
                    aria-label="Slide 4"
                    style={{
                      backgroundColor: activeTab === 3 ? "#fff" : "",
                      color: activeTab === 3 ? "#3f8acf" : "",
                      borderBottom: activeTab === 3 ? "3px solid #3f8acf" : "none",  
                    }}
                    onClick={() => handleButtonClick(3)}
                  >
                    <div className="mediaquery_immm">
                      {/* <img src={imggg4} className="img-fluid" /> */}
                      <PiSpeakerHighLight />
                    </div>{" "}
                    <div className="text_change_font_size">
                      <h6 className="text-center fw-bold">Advertising</h6>
                    </div>
                  </button>{" "}
                </li>
                <li>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={4}
                    className={activeTab === 4 ? "active" : ""}
                    aria-label="Slide 5"
                    style={{
                      backgroundColor: activeTab === 4 ? "#fff" : "",
                      color: activeTab === 4 ? "#3f8acf" : "", 
                      borderBottom: activeTab === 4 ? "3px solid #3f8acf" : "none", 
                    }}
                    onClick={() => handleButtonClick(4)}
                  >
                    <div className="mediaquery_immm">
                      {/* <img src={imggg5} className="img-fluid" /> */}
                      <SlSocialGoogle />
                    </div>{" "}
                    <div className="text_change_font_size">
                      <h6 className="text-center fw-bold">Social Media</h6>
                    </div>{" "}
                  </button>{" "}
                </li>
                <li>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={5}
                    className={activeTab === 5 ? "active" : ""}
                    aria-label="Slide 6"
                    style={{
                      backgroundColor: activeTab === 5 ? "#fff" : "",
                      color: activeTab === 5 ? "#3f8acf" : "", 
                      borderBottom: activeTab === 5 ? "3px solid #3f8acf" : "none", 
                    }}
                    onClick={() => handleButtonClick(5)}
                  >
                    <div className="mediaquery_immm">
                      {/* <img src={imggg6} className="img-fluid" /> */}
                      <BsPersonWorkspace />
                    </div>{" "}
                    <div className="text_change_font_size">
                      <h6 className="text-center fw-bold">Agency</h6>{" "}
                    </div>{" "}
                  </button>{" "}
                </li>
              </ul>
            </div>
            <div id="carouselExampleIndicators" className="carousel slide">
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={0}
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                  style={{
                    backgroundColor: activeTab === 6 ? "#fff" : "",
                    color: activeTab === 6 ? "#3f8acf" : "",
                    borderBottom: activeTab === 6 ? "3px solid #3f8acf" : "none",  
                  }}
                  onClick={() => handleButtonClick(1)}
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={1}
                  aria-label="Slide 2"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={3}
                  aria-label="Slide 3"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={4}
                  aria-label="Slide 3"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={5}
                  aria-label="Slide 3"
                />
              </div>
              <div className="carousel-inner ">
                <div className="carousel-item active">
                  <div className=" mb-3" style={{ Width: 1000 }}>
                    <div className="row g-0">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="card-body change_clr_butonnn">
                          <h3 className="mx-5 mt-4 fw-bold">
                            Grow organic traffic with our 
                            complete and easy SEO 
                            tools & workflow{" "}
                          </h3>

                          <ul className="mx-5">
                            <li className=" pt-3">
                              Uncover millions of national & local keyboards
                            </li>
                            <li className=" pt-1">
                              Analyze and domain's backlink profile
                            </li>
                            <li className=" pt-1">Run techical SEO audits</li>
                            <li className=" pt-1">
                              Track your SERP positions daily
                            </li>
                          </ul>
                          <br />
                          <button
                            type="button"
                            class="btn  fw-bold px-3 py-2 mt-3 mx-5"
                          >
                            Try SEO Toolkit
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 change_img_content_text mt-2">
                        <img
                          src="https://cdn.semrush.com/static/index/tools-new/seo.94e1f354aed0.svg"
                          className="img-fluid rounded-start Home_image_123_456"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className=" mb-3" style={{ Width: 1000 }}>
                    <div className="row g-0">
                      <div className="col-md-6">
                        <div className="card-body change_clr_butonnn">
                          <h3 className="mx-5 mt-4 fw-bold">
                            Create content that ranks  (no expert
                            knowledge  required )
                          </h3>

                          <ul className="mx-5">
                            <li className=" pt-3">
                              Find topics that resonate with your audience
                            </li>
                            <li className=" pt-1">
                              Get actionable tips to create SEO-friendly content
                            </li>
                            <li className=" pt-1">
                              Optimize your content for engagemnet and organic
                              traffic
                            </li>
                            <li className=" pt-1">
                              Use AI features to easily rewrite and improve your
                              copy
                            </li>
                          </ul>
                          <br />

                          <button
                            type="button"
                            class="btn  fw-bold px-3 py-2  mx-5"
                          >
                            Try Content Marketing ToolKit
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 change_img_content_text mt-3">
                        <img
                          src="https://cdn.semrush.com/static/index/tools-new/content_marketing.72972ff0d800.svg"
                          className="img-fluid rounded-start "
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className=" mb-3" style={{ Width: 1000 }}>
                    <div className="row g-0">
                      <div className="col-md-6">
                        <div className="card-body change_clr_butonnn">
                          <h3 className="mx-5 mt-3 fw-bold">
                            Unveil your competitors' marketing strategy
                            and tatics
                          </h3>

                          <ul className="mx-5">
                            <li className=" pt-3">
                              Analysis traffic of any website
                            </li>
                            <li className=" pt-1">
                              Unveil Competitor promotion strategious
                            </li>
                            <li className=" pt-1">
                              Get ideas for Growing your market share
                            </li>
                            <li className=" pt-1">
                              Discover keyword & backlink gap
                            </li>
                          </ul>
                          <br />
                          <button
                            type="button"
                            class="btn  fw-bold px-3 py-2 mt-3 mx-5"
                          >
                            Try Competitive Research Toolkit
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 change_img_content_text mt-3">
                        <img
                          src="https://cdn.semrush.com/static/index/tools-new/competitive_research.33252b03170c.svg"
                          className="img-fluid rounded-start"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className=" mb-3" style={{ Width: 1000 }}>
                    <div className="row g-0">
                      <div className="col-md-6">
                        <div className="card-body change_clr_butonnn">
                          <h3 className="mx-5 mt-3 fw-bold">
                            Discover how to reach more <br /> prospects while
                            spending <br /> less
                          </h3>

                          <ul className="mx-5">
                            <li className=" pt-3">
                              Find the best keywords for every PPC campaign
                            </li>
                            <li className=" pt-1">
                              Monitor your competitor’s ad copies and landing
                              pages
                            </li>
                            <li className=" pt-1">
                              Optimize your Advertising spend (at a local level)
                            </li>
                            <li className=" pt-1">
                              Analyze Google Shopping ad campaigns
                            </li>
                          </ul>
                          <br />
                          <button
                            type="button"
                            class="btn  fw-bold px-3 py-2 mt-3 mx-5"
                          >
                            Try Advertising Toolkit
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 change_img_content_text mt-3">
                        <img
                          src="https://cdn.semrush.com/static/index/tools-new/agency.37f691e98e26.svg"
                          className="img-fluid rounded-start"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className=" mb-3" style={{ Width: 1000 }}>
                    <div className="row g-0">
                      <div className="col-md-6">
                        <div className="card-body change_clr_butonnn">
                          <h3 className="mx-5 mt-3 fw-bold">
                            Build your most effective <br /> social media
                            strategy
                          </h3>

                          <ul className="mx-5">
                            <li className=" pt-3">
                              Schedule and post content on social media
                            </li>
                            <li className=" pt-1">
                              Analyze the performance of your posts
                            </li>
                            <li className=" pt-1">
                              Analyze the performance of your posts
                            </li>
                            <li className=" pt-1">
                              Manage your public brand reputation
                            </li>
                          </ul>
                          <br />
                          <button
                            type="button"
                            class="btn  fw-bold px-3 py-2 mt-3 mx-5"
                          >
                            Try Social Media Toolkit
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 change_img_content_text mt-3">
                        <img
                          src="https://cdn.semrush.com/static/index/tools-new/social_media.6b7572746c09.svg"
                          className="img-fluid rounded-start"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className=" mb-3" style={{ Width: 1000 }}>
                    <div className="row g-0">
                      <div className="col-md-6">
                        <div className="card-body change_clr_butonnn">
                          <h3 className="mx-5 mt-3 fw-bold">
                            Streamline your agency processes for growth
                          </h3>

                          <ul className="mx-5">
                            <li className="fw-bold pt-3">
                              Get quality leads regularly
                            </li>
                            <li className=" pt-1">
                              Automate client reporting and pitching
                            </li>
                            <li className=" pt-1">
                              Create white-label client portals and share
                              project progress details
                            </li>
                            <li className=" pt-1">
                              Manage the entire client workflow with CRM
                            </li>
                          </ul>
                          <br />
                          <button
                            type="button"
                            class="btn  fw-bold px-3 py-2 mt-3 mx-5"
                          >
                            Try Agency Solutions
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 change_img_content_text mt-3">
                        <img
                          src="https://cdn.semrush.com/static/index/tools-new/seo.94e1f354aed0.svg"
                          className="img-fluid rounded-start"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home_TabMessage_1;
