import React, { useState } from "react";
import "./SideBar.css";
import { NavLink, Link } from "react-router-dom";
import SIDEBAR_ICON_SIDEBAR from "../../Assets/WebApp_Logo_SideBar.png";
import Dashboard_Icon_SideBar_Trans from "../../Assets/Dashboard_Icon_SideBar_Trans.png";
import Create_Post_Icon_SideBar from "../../Assets/Create_Post_Icon_SideBar.png";
import Chat_Icon_SideBar from "../../Assets/Chat_Icon_SideBar.png";
import Settings_Icon_SideBar from "../../Assets/Settings_Icon_SideBar.png";
import Profile_Icon_SideBar from "../../Assets/Profile_Icon_SideBar.png";
import LogOut_Icon_SideBar from "../../Assets/LogOut_Icon_SideBar.png";
import { CiSignpostDuo1 } from "react-icons/ci";
import { MdAddTask } from "react-icons/md";
import { MdOutlineAccountBox } from "react-icons/md";

const Sidebar = ({ className }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCreatePostClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={className}>
      <div className="Large_SideBar_Logo_Container fw-bold">
        <div className="MAIN_LOGO_ICON">
          <NavLink to={`/`} className={`disable_link_style`}>
            <img
              src={SIDEBAR_ICON_SIDEBAR}
              className="Large_Sidebar_Icon"
              alt="WebApp_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU LOGO_FONT">Pixclick</div>
          </NavLink>
        </div>
      </div>

      <div className="Large_SideBar_Menus SIDEBAR_ICON_FONT">
        <div className="SIDEBAR_ICON_SIDEBAR">
          <NavLink to={`/advertisementpanel`} className={`disable_link_style`}>
            <img
              src={Dashboard_Icon_SideBar_Trans}
              className="Large_Sidebar_Icon"
              alt="Dashboard_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU">Dashboard</div>
          </NavLink>
        </div>
        <div className="SIDEBAR_ICON_SIDEBAR">
          <div className={`disable_link_style`} onClick={handleCreatePostClick}>
            <img
              src={Create_Post_Icon_SideBar}
              className="Large_Sidebar_Icon"
              alt="Create_Post_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU">Create</div>
          </div>
          {showPopup && (
            <div className="popup" onClick={closePopup}>
              <div className="popup-content mx-3 d-flex flex-column gap-3 toggle_function">
                <Link
                  to={`/advertisementpanel/createpost`}
                  className="text-decoration-none toggle_options"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <CiSignpostDuo1 size={25} className="Large_Sidebar_Icon" />
                    <span className="SMALL_STYLE_MENU">Post</span>
                  </div>
                </Link>
                <Link
                  to={`/advertisementpanel/createtask`}
                  className="text-decoration-none toggle_options"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <MdAddTask size={25} className="Large_Sidebar_Icon" />
                    <span className="SMALL_STYLE_MENU">Task</span>
                  </div>
                </Link>
                <Link
                  to={`/advertisementpanel/createaccount`}
                  className="text-decoration-none toggle_options"
                >
                  <div className="d-flex gap-2 mb-3 align-items-center">
                    <MdOutlineAccountBox
                      size={25}
                      className="Large_Sidebar_Icon"
                    />
                    <span className="SMALL_STYLE_MENU">Account</span>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="SIDEBAR_ICON_SIDEBAR">
          <NavLink to={`/`} className={`disable_link_style`}>
            <img
              src={Chat_Icon_SideBar}
              className="Large_Sidebar_Icon"
              alt="Chat_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU">Chat</div>
          </NavLink>
        </div>
        <div className="SIDEBAR_ICON_SIDEBAR">
          <NavLink to={`/`} className={`disable_link_style`}>
            <img
              src={Settings_Icon_SideBar}
              className="Large_Sidebar_Icon"
              alt="Settings_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU">Setting</div>
          </NavLink>
        </div>
        <div className="SIDEBAR_ICON_SIDEBAR">
          <NavLink to={`/`} className={`disable_link_style`}>
            <img
              src={Profile_Icon_SideBar}
              className="Large_Sidebar_Icon"
              alt="Profile_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU">Profile</div>
          </NavLink>
        </div>
        <div className="SIDEBAR_ICON_SIDEBAR">
          <NavLink to={`/`} className={`disable_link_style`}>
            <img
              src={LogOut_Icon_SideBar}
              className="Large_Sidebar_Icon"
              alt="LogOut_Icon_SideBar"
            />
            <div className="SMALL_STYLE_MENU">Logout</div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
