import React from "react";
import "./AboutUs_Main.css";


const AboutUs_Investors_07 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-12">
          <h1 className="fw-bold ">Investors</h1>
          <p className="  fs-5 mt-4">
            If you are an investor or just want to become—PixClick has collected
            all the up to date information in our Investors page. Feel free to
            discover the most recent details and become part of the PixClick
            investors community.
          </p>
        </div>

        <div className="col-lg-6 col-md-12 col-12 ">
          <img src={"https://static.seekingalpha.com/cdn/s3/uploads/getty_images/1354117292/image_1354117292.jpg?io=getty-c-crop-16-9"} className="img-fluid rounded-4 animated_image_about" alt="" />
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default AboutUs_Investors_07;
