import React, { useEffect } from "react";
import "./AboutUs_Main.css";
import AOS from "aos";
import "aos/dist/aos.css";
import start from "./AboutAssests/images/star.svg";
import Heart from "./AboutAssests/images/Heart.svg";
import Blue from "./AboutAssests/images/blue.svg";
import Green from "./AboutAssests/images/greencolor.svg";

const AboutUs_iconsbrands_02 = () => {
  useEffect(() => {
    const initializeAOS = () => {
      AOS.init();
    };
    const delay = 2000;

    const timeoutId = setTimeout(initializeAOS, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="container-fluid bg-light p-5 py-5  rounded-5  ">
       
      <div className="row text-center mt-5">
       
        <div className="col-lg-3 col-md-12 col-12 ">
          <img
            src={start}
            alt="Star"
            className="corner-image top-right img-fluid "
            data-aos="flip-left"
          />
          <h1 className="AboutUs_icons_style_size ">8</h1>
          <p className="fw-bold mt-4">Years of Experience</p>
        </div>
        <div className="col-lg-3 col-md-12 col-12">
          <img
            src={Heart}
            alt="Star"
            className="corner-image top-right img-fluid"
            data-aos="flip-left"
          />
          <h1 className="AboutUs_icons_style_size ">5M</h1>
          <p className="fw-bold mt-4">users have already tried PixClick</p>
        </div>
        <div className="col-lg-3 col-md-12 col-12">
          <img
            src={Blue}
            alt="Star"
            className="corner-image top-right img-fluid"
            data-aos="flip-left"
          />
          <h1 className="AboutUs_icons_style_size">143</h1>
          <p className="fw-bold mt-4">Countries served</p>
        </div>
        <div className="col-lg-3 col-md-12 col-12">
          <img
            src={Green}
            alt="Star"
            className="corner-image top-right img-fluid"
            data-aos="flip-left"
          />
          <h1 className="AboutUs_icons_style_size">13</h1>
          <p className="fw-bold mt-4">offices</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs_iconsbrands_02;
