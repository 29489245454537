import React, { useState } from "react";
import "./Createtask.css";
import { Link, useNavigate } from "react-router-dom";
import DUMMY_PROFILE_PIC from "../../../../Assets/DUMMY_PROFILE_PIC.png";
import { BiPencil } from "react-icons/bi";
import { getAdvertiserId } from "../../../../Utils/Storage";
import { uploadFile } from "../../../../Utils/Fileupload";
import { createTask } from "../../../../Api/API_List/Advertisertask";
import { toast } from "react-toastify";

const Createtask = () => {
  const handleClick = () => {
    const fileInput = document.getElementById("file-upload");
    fileInput.click();
  };

  let initialState = {
    advertiserId: getAdvertiserId(),
    logo: "",
    appName: "",
    description: "",
    step1: "",
    step2: "",
    step3: "",
    coinValue: "",
    appUrl: "",
    webSiteUrl: "",
    splitUser: "",
  };

  let initialStateerror = {
    logo: { required: false },
    appName: { required: false },
    description: { required: false },
    step1: { required: false },
    step2: { required: false },
    step3: { required: false },
    coinValue: { required: false },
    appUrl: { required: false },
    webSiteUrl: { required: false },
    splitUser: { required: false },
  };

  const [task, setTask] = useState(initialState);
  const [errors, setErrors] = useState(initialStateerror);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleValidation = (data) => {
    const error = { ...initialStateerror };
    if (data.logo === "") {
      error.logo.required = true;
    }
    if (data.appName === "") {
      error.appName.required = true;
    }
    if (data.description === "") {
      error.description.required = true;
    }
    if (data.coinValue === "") {
      error.coinValue.required = true;
    }
    if (data.appUrl === "") {
      error.appUrl.required = true;
    }
    if (data.webSiteUrl === "") {
      error.webSiteUrl.required = true;
    }
    if (data.splitUser === "") {
      error.splitUser.required = true;
    }
    return error;
  };

  const handleInputs = (event) => {
    setTask({ ...task, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...task,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    const folder = "Tasks/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setTask({ ...task, logo: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const newError = handleValidation(task);
    setErrors(newError);
    const allInputValid = Object.values(newError);
    const valid = allInputValid.some((x) => x.required === true);
    if (!valid) {
      createTask({
        ...task,
        steps: {
          step1: task.step1,
          step2: task.step2,
          step3: task.step3,
        },
      })
        .then((res) => {
          toast.success(res?.data?.message);
          setSubmitted(false);
          navigate("/advertisementpanel/alltasksview");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-between">
              <div>
                <h3>Create Task</h3>
              </div>
              <div>
                <Link to={"/advertisementpanel/alltasksview"}>
                  <button className="btn btn-primary">View All Tasks</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 d-flex justify-content-center">
              <div className="preview-container position-relative">
                <img
                  src={task?.logo || DUMMY_PROFILE_PIC}
                  width="100"
                  height="100"
                  alt="Preview"
                  className="preview-image profile_image"
                />
                <div className="edit_icon">
                  <BiPencil size={30} onClick={handleClick} />
                </div>
              </div>
              <input
                className="file-upload mt-2 profile_img"
                name="logo"
                id="file-upload"
                onChange={handleFileInputs}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  App Name<span className="text-danger">*</span>
                </label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter App Name"
                  name="appName"
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  Description<span className="text-danger">*</span>
                </label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter Description"
                  name="description"
                  onChange={handleInputs}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  App URL <span className="text-danger">*</span>
                </label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter App URL"
                  name="appUrl"
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  Register URL<span className="text-danger">*</span>
                </label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter Register URL"
                  name="webSiteUrl"
                  onChange={handleInputs}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  Coins<span className="text-danger">*</span>
                </label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter Coins"
                  name="coinValue"
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  Set User Limit<span className="text-danger">*</span>
                </label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter User Limit"
                  name="splitUser"
                  onChange={handleInputs}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-8">
              <div className="d-flex flex-column gap-2 input_task_fields">
                <label>
                  Steps<span className="text-danger">*</span>
                </label>
                <input
                  placeholder="Enter Step-1"
                  className="w-100"
                  type="text"
                  name="step1"
                  onChange={handleInputs}
                />
                <input
                  placeholder="Enter Step-2"
                  className="w-100"
                  type="text"
                  name="step2"
                  onChange={handleInputs}
                />
                <input
                  placeholder="Enter Step-3"
                  className="w-100"
                  type="text"
                  name="step3"
                  onChange={handleInputs}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-lg-12">
              <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary">Post Now</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Createtask;
