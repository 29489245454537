import React from "react";
import "./Footer.css";
import FOOTER_LOGO from "../Footer/Assests_Footer/images/Pixclicklogonew.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="background_color_Footer ">
        <div className="container">
          {/* row-1 */}
          <div className="row">
            <div className="col-lg-7 col-md-6 col-12 mt-3 py-2 d-flex">
              <div>
                <button type="button" className="btn btn-outline-light px-5">
                  <i className="bi bi-envelope-fill"></i> Contact Us
                </button>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-12 mt-3 py-2  d-flex justify-content-end">
              <button
                type="button"
                className="btn text-white px-5 btn-lg fw-bold"
                style={{ backgroundColor: " #3f8acf" }}
              >
                Get started with Pixclick!
              </button>
            </div>
          </div>
          {/* end of row-1 */}

          {/* Row-2 */}
          <div className="row">
            <div className="col-lg-2 col-md-6 col-12 ">
              <ul className="mt-5 Footerlist_01 ">
                <h6 className=" fw-bold text-white fs-5">Pixclick</h6>
                <a href="/Features_Main">
                  {" "}
                  <li>Features</li>
                </a>
                <li>Pricing</li>
                <li>Success Stories</li>
                <li>Stats and Facts</li>
                <li>Insights</li>
                <li>News</li> <br />
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-12 ">
              <ul className="mt-5 Footerlist_01 ">
                <h6 className=" fw-bold text-white fs-5">COMMUNITY</h6>
                <li>Pixclick Blog</li>
                <li>Webinars</li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-12 ">
              <ul className="mt-5 Footerlist_01 ">
                <h6 className=" fw-bold text-white fs-5">Help</h6>
                <li>Knowledge Base</li>
                <li>Academy</li>
                <li>PixClick API</li>
              </ul>{" "}
            </div>
            <div className="col-lg-2 col-md-6 col-12 ">
              <ul className="mt-5 Footerlist_01 ">
                <h6 className=" fw-bold text-white fs-5">COMPANY</h6>
                <a href="/AboutUs_Main">
                  <li>About Us</li>
                </a>
                <li>Newsroom</li>
                <li>Careers</li>
                <li>Partners</li>
                <li>Legal Info</li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-12 ">
              <ul className="mt-5 Footerlist_01 ">
                <h6 className=" fw-bold text-white fs-5">FOLLOW US</h6>
                <li>Twitter</li>
                <li>Facebook</li>
                <li>LinkedIn</li>
                <li>Instagram</li>
                <li>YouTube</li>
                <li>Pinterest</li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-12 ">
              <ul className="mt-5 Footerlist_01 ">
                <h6 className=" fw-bold text-white fs-5">POLICIES</h6>
                <Link to="/privacypolicy" className="text-decoration-none">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="/termsandconditions" className="text-decoration-none">
                  <li>Terms and Conditions</li>
                </Link>
                <Link to="/" className="text-decoration-none">
                  <li>Referral Policy</li>
                </Link>
                <Link to="/cancellationpolicy" className="text-decoration-none">
                  <li>Refund / Cancellation Policy</li>
                </Link>
                <Link to="/coinspolicy" className="text-decoration-none">
                  <li>Coins Policy</li>
                </Link>
              </ul>
            </div>
          </div>
          {/* end of row-2 */}
        </div>
      </div>
      <div className="Footerbackground-downside ">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-6 col-12"></div>
            <div className="col-lg-11 col-md-6 col-12 Footerimage123  text-center">
              <a href="/">
                <img src={FOOTER_LOGO} alt="Footer Logo" />
              </a>
              <span className="spansize123">
                &nbsp;&nbsp;&nbsp; © 2008 - 2023 Pixclick. All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
