import { Advertiser_PostDetails_EP, Advertiser_SignUp_EP } from "../EndPoints";
import MainApi from "../MainApi";

export const Getsingleadvertiser = (data) => {
  return MainApi.get(`${Advertiser_SignUp_EP}/getSingleAdvertiser`, {
    params: { _id: data },
  });
};

export const UpdateAdvertiser = (data) => {
  return MainApi.put(`${Advertiser_SignUp_EP}/`, data);
};

export const Advertiserpostdetails = (data) => {
  return MainApi.get(`${Advertiser_PostDetails_EP}/advertiserPostDetails`, {
    params: { advertiserId: data },
  });
};


