import React, { useState } from "react";
import "./Right_Sidebar.css";
import Notify from "../../Assets/right sidebar/notification.png";
import Human from "../../Assets/right sidebar/profile-male.png";
import Reading from "../../Assets/right sidebar/Group 4573.png";
import Hand from "../../Assets/right sidebar/hand.png";
import Manlogo from "../../Assets/right sidebar/Ellipse 470.png";
import Womanlogo from "../../Assets/right sidebar/Ellipse 469.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Right_Sidebar = () => {
  const [date, setDate] = useState(new Date());

  const onChange = (date) => {
    setDate(date);
  };

  return (
    <div className="rightbar_main_container col-12 col-sm-12">
      <div className="container rightbar">
        <div className="profile-notify d-flex p-3">
          <img className="notify" src={Notify} alt="notify_logo" />
          <button className="btn btn-light updates rounded-pill">
            2 New Updates
          </button>
          <img className="human" src={Human} alt="human_logo" />
        </div>

        <div className="read mt-3">
          <img
            className="reading justify-content-center"
            src={Reading}
            alt="reading_book"
          />
        </div>

        <div className="mt-3">
          <div className="calendar-wrapper">
            <Calendar onChange={onChange} value={date} />
          </div>
        </div>

        <div className="footer-users d-flex flex-column gap-3 mt-3">
          <div className="d-flex justify-content-center gap-4">
            <div className="sayhi d-flex gap-1">
              <img className="hand" src={Hand} alt="hand" />
              <p>Say Hi to Kamal</p>
            </div>
            <img className="user-imgg" src={Manlogo} alt="man" />
          </div>

          <div className="d-flex justify-content-center gap-4">
            <div className="sayhi d-flex gap-1">
              <img className="hand" src={Hand} alt="hand" />
              <p>Say Hi to Sarath</p>
            </div>
            <img className="user-imgg" src={Womanlogo} alt="woman" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right_Sidebar;
