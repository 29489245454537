import React, { useState, useEffect } from "react";
import "./Forgetpassword.css";
import {
  Advertiserforgetpassword,
  Advertiserpasswordupdate,
} from "../../../Api/API_List/Advertiserlogin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Forgetpassword = () => {
  let initialstate = {
    email: "",
    newPassword: "",
    confirmPassword: "",
    otp: "",
  };

  let initialstateerror = {
    email: { required: false },
    newPassword: { required: false },
    confirmPassword: { required: false },
    otp: { required: false },
  };

  const [inputs, setInputs] = useState(initialstate);
  const [errors, setErrors] = useState(initialstateerror);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (submitted) {
      const newError = handleValidation(inputs);
      setErrors(newError);
    }
  }, [inputs, submitted]);

  const handleValidation = (data) => {
    const error = { ...initialstateerror };
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.newPassword === "") {
      error.newPassword.required = true;
    }
    if (data.confirmPassword === "") {
      error.confirmPassword.required = true;
    }
    if (data.otp === "") {
      error.otp.required = true;
    }
    return error;
  };

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleOtpsent = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    if (!newError.email.required) {
      const data = {
        email: inputs.email,
      };
      Advertiserforgetpassword(data)
        .then((res) => {
          setInputs({ ...inputs, otp: res?.data?.result?.otp }); // Update otp in state
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err?.message);
        });
    }
  };

  const handleVerifyOtp = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const newError = handleValidation(inputs);
    setErrors(newError);
    if (!newError.otp.required) {
      const data = {
        email: inputs.email,
        newPassword: inputs.newPassword,
        confirmPassword: inputs.confirmPassword,
        otp: inputs.otp,
      };
      Advertiserpasswordupdate(data)
        .then((res) => {
          setInputs(res?.data?.result);
          toast.success(res?.data?.message);
          navigate("/Login_Page");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err?.message);
        });
    }
  };

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center mt-5">
        <div className="row border rounded w-50 p-5 bg-light">
          <div className="col-12">
            <form className="form">
              <div className="form-group mt-5">
                <div>
                  <label>
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={handleInputs}
                    name="email"
                  />
                </div>
                {errors.email.required && (
                  <span className="text-danger">Email is required</span>
                )}
              </div>
              <div className="form-group mt-3">
                <div>
                  <label>
                    New Password<span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={handleInputs}
                    name="newPassword"
                  />
                </div>
                {errors.newPassword.required && (
                  <span className="text-danger">New Password is required</span>
                )}
              </div>
              <div className="form-group mt-3">
                <div>
                  <label>
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={handleInputs}
                    name="confirmPassword"
                  />
                </div>
                {errors.confirmPassword.required && (
                  <span className="text-danger">
                    Confirm Password is required
                  </span>
                )}
              </div>
              <div className="form-group mt-5 d-flex justify-content-center align-items-center">
                <div>
                  <button
                    type="button"
                    className="form-control bg-primary text-white"
                    onClick={handleOtpsent}
                  >
                    Send OTP
                  </button>
                </div>
              </div>
              <div className="form-group mt-5">
                <div>
                  <label>
                    OTP<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputs}
                    name="otp"
                  />
                </div>
                {errors.otp.required && (
                  <span className="text-danger">OTP is required</span>
                )}
              </div>
              <div className="form-group mt-5 d-flex justify-content-center align-items-center">
                <div>
                  <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className="form-control bg-primary text-white"
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpassword;
