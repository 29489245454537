import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import "./Dashboard_Chart.css";

const chartSetting = {
  width: 1000,
  height: 350,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-10px, 0)",
    },
  },
};

const customColors = [
  "#FF5733",
  "#00C853",
  "#2979FF",
  "#F50057",
  "#00B8D4",
  "#FFD600",
  "#FF6D00",
  "#4CAF50",
];

const dataset = [
  {
    month: "Jan",
    TaskCompleters: 150,
    Tasks: 130,
    Posts: 180,
    Clicks: 250,
    Followers: 200,
    Likes: 120,
    Comments: 110,
    WalletCash: 2000,
  },
  {
    month: "Feb",
    TaskCompleters: 145,
    Tasks: 125,
    Posts: 170,
    Clicks: 240,
    Followers: 190,
    Likes: 115,
    Comments: 105,
    WalletCash: 1900,
  },
  {
    month: "Mar",
    TaskCompleters: 160,
    Tasks: 135,
    Posts: 185,
    Clicks: 260,
    Followers: 210,
    Likes: 125,
    Comments: 115,
    WalletCash: 2050,
  },
  {
    month: "Apr",
    TaskCompleters: 155,
    Tasks: 130,
    Posts: 175,
    Clicks: 255,
    Followers: 205,
    Likes: 120,
    Comments: 110,
    WalletCash: 1980,
  },
  {
    month: "May",
    TaskCompleters: 165,
    Tasks: 140,
    Posts: 190,
    Clicks: 270,
    Followers: 220,
    Likes: 130,
    Comments: 120,
    WalletCash: 2100,
  },
  {
    month: "Jun",
    TaskCompleters: 170,
    Tasks: 145,
    Posts: 195,
    Clicks: 280,
    Followers: 225,
    Likes: 135,
    Comments: 125,
    WalletCash: 2150,
  },
  {
    month: "Jul",
    TaskCompleters: 175,
    Tasks: 150,
    Posts: 200,
    Clicks: 285,
    Followers: 230,
    Likes: 140,
    Comments: 130,
    WalletCash: 2200,
  },
  {
    month: "Aug",
    TaskCompleters: 180,
    Tasks: 155,
    Posts: 205,
    Clicks: 290,
    Followers: 235,
    Likes: 145,
    Comments: 135,
    WalletCash: 2250,
  },
  {
    month: "Sep",
    TaskCompleters: 185,
    Tasks: 160,
    Posts: 210,
    Clicks: 295,
    Followers: 240,
    Likes: 150,
    Comments: 140,
    WalletCash: 2300,
  },
  {
    month: "Oct",
    TaskCompleters: 190,
    Tasks: 165,
    Posts: 215,
    Clicks: 300,
    Followers: 245,
    Likes: 155,
    Comments: 145,
    WalletCash: 2350,
  },
  {
    month: "Nov",
    TaskCompleters: 195,
    Tasks: 170,
    Posts: 220,
    Clicks: 305,
    Followers: 250,
    Likes: 160,
    Comments: 150,
    WalletCash: 2400,
  },
  {
    month: "Dec",
    TaskCompleters: 200,
    Tasks: 175,
    Posts: 225,
    Clicks: 310,
    Followers: 255,
    Likes: 165,
    Comments: 155,
    WalletCash: 2450,
  },
];

const valueFormatter = (value) => `${value}`;

function Dashboard_Chart() {
  return (
    <div className="DASHBOARD_CHART_BG d-flex justify-content-center my-5">
      <BarChart
        className=""
        dataset={dataset}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={[
          {
            dataKey: "TaskCompleters",
            label: "Task Completers   ",
            valueFormatter,
          },
          { dataKey: "Tasks", label: "Tasks  ", valueFormatter },
          { dataKey: "Posts", label: "Posts  ", valueFormatter },
          { dataKey: "Clicks", label: "Clicks  ", valueFormatter },
          { dataKey: "Followers", label: "Followers  ", valueFormatter },
          { dataKey: "Likes", label: "Likes  ", valueFormatter },
          { dataKey: "Comments", label: "Comments  ", valueFormatter },
          { dataKey: "WalletCash", label: "Wallet Cash  ", valueFormatter },
        ]}
        colors={customColors} // Customizing bar colors
        {...chartSetting}
      />
    </div>
  );
}

export default Dashboard_Chart;
