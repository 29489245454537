import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Dashboard_Carousel.css";
import "../../../../Components/Common.css";
import CARD_1_Dashboard_Total_Task_Icon from "../../../../Assets/CARD_1_Dashboard_Total_Task_Icon.png";
import CARD_2_Dashboard_Total_Link_Icon from "../../../../Assets/CARD_2_Dashboard_Total_Link_Icon.png";
import CARD_3_Dashboard_Click_Count_Icon from "../../../../Assets/CARD_3_Dashboard_Click_Count_Icon.png";
import CARD_4_Dashboard_Followers_Icon from "../../../../Assets/CARD_4_Dashboard_Followers_Icon.png";
import CARD_5_Dashboard_Likes_Icon from "../../../../Assets/CARD_5_Dashboard_Likes_Icon.png";
import CARD_6_Dashboard_Comments_Icon from "../../../../Assets/CARD_6_Dashboard_Comments_Icon.png";
import CARD_7_Dashboard_Post_Counts_Icon from "../../../../Assets/CARD_7_Dashboard_Post_Counts_Icon.png";
import CARD_8_Dashboard_Earnings_Icon from "../../../../Assets/CARD_8_Dashboard_Earnings_Icon.png";
import Left_Arrow_Slider from "../../../../Assets/Left_Arrow_Slider.png";
import Right_Arrow_Slider from "../../../../Assets/Right_Arrow_Slider.png";
import {
  Advertiserpostdetails,
  Getsingleadvertiser,
} from "../../../../Api/API_List/Advertiser";
import { getAdvertiserId } from "../../../../Utils/Storage";

function Dashboard_Carousel() {
  const goNext = () => {
    if (swiper.current && swiper.current.swiper) {
      swiper.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper.current && swiper.current.swiper) {
      swiper.current.swiper.slidePrev();
    }
  };

  const swiper = useRef(null);

  const [advertiser, setAdvertiser] = useState({});
  const [postDetails, setPostDetails] = useState({});

  const SingleAdvertiser = () => {
    const id = getAdvertiserId();
    Getsingleadvertiser(id)
      .then((res) => {
        setAdvertiser(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Postdetails = () => {
    const id = getAdvertiserId();
    Advertiserpostdetails(id)
      .then((res) => {
        setPostDetails(res?.data?.result[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    SingleAdvertiser();
    Postdetails();
  }, []);

  return (
    <>
      <div className="DASHBOARD_BG">
        {/* Add custom arrow images and event handlers */}
        <div className="swiper-buttons SLIDER_HEAD_ARROW pb-3 pt-1">
          <div className="TOTAL_REACH">
            <span className=" ">Total Reach</span>
          </div>
          <div className="ARRORS">
            <span onClick={goPrev}>
              <img src={Left_Arrow_Slider} alt="Previous" />
            </span>
            <span onClick={goNext}>
              <img src={Right_Arrow_Slider} alt="Previous" />
            </span>
          </div>
        </div>
        <Swiper
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          className="mySwiper"
          ref={swiper}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 6,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img className="" src={CARD_1_Dashboard_Total_Task_Icon} alt="" />
            </div>

            <div className="COUNT_DASHBOARD pt-3">
              <span>
                {advertiser?.completeTaskOnUser
                  ? advertiser?.completeTaskOnUser
                  : "0"}
              </span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1  ">
              <span>Task Completers</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img className="" src={CARD_2_Dashboard_Total_Link_Icon} alt="" />
            </div>

            <div className="COUNT_DASHBOARD   pt-3">
              <span>{advertiser?.taskCount ? advertiser?.taskCount : "0"}</span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1">
              <span>Task Counts</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img
                className=""
                src={CARD_7_Dashboard_Post_Counts_Icon}
                alt=""
              />
            </div>

            <div className="COUNT_DASHBOARD   pt-3">
              <span>{advertiser?.postCount ? advertiser?.postCount : "0"}</span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1">
              <span>Post Counts</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img
                className=""
                src={CARD_3_Dashboard_Click_Count_Icon}
                alt=""
              />
            </div>

            <div className="COUNT_DASHBOARD   pt-3">
              <span>
                {postDetails?.totalClickCount
                  ? postDetails?.totalClickCount
                  : "0"}
              </span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1  ">
              <span>Click Counts</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img className="" src={CARD_4_Dashboard_Followers_Icon} alt="" />
            </div>

            <div className="COUNT_DASHBOARD  pt-3">
              <span>
                {advertiser?.followersCount ? advertiser?.followersCount : "0"}
              </span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1  ">
              <span>Follower Counts</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img className="" src={CARD_5_Dashboard_Likes_Icon} alt="" />
            </div>

            <div className="COUNT_DASHBOARD   pt-3">
              <span>
                {postDetails?.totalLikeCount
                  ? postDetails?.totalLikeCount
                  : "0"}
              </span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1  ">
              <span>Like Counts</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img className="" src={CARD_6_Dashboard_Comments_Icon} alt="" />
            </div>

            <div className="COUNT_DASHBOARD   pt-3">
              <span>
                {postDetails?.totalCommentsCount
                  ? postDetails?.totalCommentsCount
                  : "0"}
              </span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1  ">
              <span>Comment Counts</span>
            </div>
          </SwiperSlide>

          <SwiperSlide className="SINGLE_CARD">
            <div className="DASHBOARD_CARD">
              <img className="" src={CARD_8_Dashboard_Earnings_Icon} alt="" />
            </div>

            <div className="COUNT_DASHBOARD   pt-3">
              <span>
                ₹{advertiser?.coinValue ? advertiser?.coinValue : "0"}
              </span>
            </div>
            <div className="COUNT_CONTENT_DASHBOARD pt-1  ">
              <span>Wallet Cash</span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default Dashboard_Carousel;
