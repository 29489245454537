import React, { useState } from "react";
import "./Login_Page.css";
import WebApp_Logo_SideBar from "../../../Assets/WebApp_Logo_SideBar.png";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink } from "react-router-dom";
import { isValidEmail } from "../../../Utils/Validation";
import { Advertiserlogin } from "../../../Api/API_List/Advertiserlogin";
import { isAuthenticated } from "../../../Utils/Auth";
import { saveToken } from "../../../Utils/Storage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login_Page = () => {
  let initialstate = {
    email: "",
    password: "",
  };

  let initialstateerror = {
    email: { required: false },
    password: { required: false },
  };

  const [inputs, setInputs] = useState(initialstate);
  const [errors, setErrors] = useState(initialstateerror);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleValidation = (data) => {
    const error = { ...initialstateerror };
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.password === "") {
      error.password.required = true;
    }
    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    return error;
  };

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);

    if (!newError.email.required && !newError.password.required) {
      const data = {
        email: inputs.email,
        password: inputs.password,
      };

      Advertiserlogin(data)
        .then((res) => {
          const token = res?.data?.result?.token;
          const loginType = res?.data?.result?.loginType;
          const advertiserId = res?.data?.result?.advertiserDetails?._id;

          if (loginType === "advertiser") {
            const tokenData = {
              token: token,
              loginType: loginType,
              advertiserDetails: {
                _id: advertiserId,
              },
            };
            saveToken(tokenData);
            if (isAuthenticated()) {
              navigate("/");
            }
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.err?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div className="LOGIN_PAGE_BG   overflow-x-hidden overflow-y-hidden ">
      <div className="container LOGIN_CONTAINER  px-5 py-5">
        <div className="row py-5 LOGIN_PAGE_BOX_SHADOW shadow rounded-3">
          <div className="col-12">
            <div className="LOGIN_PAGE_LOGO d-flex gap-1 align-items-center">
              <img src={WebApp_Logo_SideBar} className="img-fluid " alt="" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="WELCOME_CONTENT pt-3">Welcome Back!</div>
              <div className="LOGIN_PHONE_INPUT pt-3">
                <label className="LOGIN_PHONE_NUMBER align-items-left">
                  <b>
                    E-mail<span className="text-danger">*</span>
                  </b>
                </label>
                <br />
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="Email_INPUT"
                  name="email"
                  onChange={handleInputs}
                />
                {errors.email.required ? (
                  <span className="text-danger form-text">
                    This field is required
                  </span>
                ) : errors.email.valid ? (
                  <span className="text-danger form-text">
                    Please enter valid email
                  </span>
                ) : null}
              </div>
              <div className="LOGIN_PHONE_INPUT pt-3">
                <label className="LOGIN_PHONE_NUMBER align-items-left">
                  <b>
                    Password<span className="text-danger">*</span>
                  </b>
                </label>
                <br />
                <input
                  type="password"
                  placeholder="Enter Your Password"
                  className="Email_INPUT"
                  name="password"
                  onChange={handleInputs}
                />
                {errors.password.required ? (
                  <span className="text-danger form-text">
                    This field is required
                  </span>
                ) : errors.password.valid ? (
                  <span className="text-danger form-text">
                    Please enter valid password
                  </span>
                ) : null}
              </div>
              <div>
                <button
                  type="submit"
                  className="w-100 mt-3 text-white border rounded p-2"
                  style={{ backgroundColor: "#3f8acf" }}
                >
                  Login
                </button>
              </div>
              <div>
                <Link
                  to="/Forget_Password"
                  className="d-flex justify-content-end"
                >
                  <p>Forgotten Password?</p>
                </Link>
              </div>
            </form>

            <div className="pt-4 d-flex flex-column gap-5">
              <NavLink to="/SignUp_Page_1">
                <p className="WELCOME_PAGE_SIGNUP_PATH align-items-center">
                  Don't have a account?
                  <span className="fw-bold mx-1">Signup</span>
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_Page;
