import React, { useEffect, useState } from "react";
import "./Listtask.css";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { Deletetask, Gettask } from "../../../../Api/API_List/Advertisertask";
import { toast } from "react-toastify";
import { getAdvertiserId } from "../../../../Utils/Storage";

const Listtask = () => {
  const [task, setTask] = useState([]);

  const getTask = () => {
    Gettask()
      .then((res) => {
        setTask(res?.data?.result.filter((task) => task?.advertiserId === getAdvertiserId()));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  };

  useEffect(() => {
    getTask();
  }, []);

  const deleteTask = (id) => {
    Deletetask(id)
      .then((res) => {
        toast.success(res?.data?.message);
        getTask();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <Link to={"/advertisementPanel/createtask"}>
              <span className="text-primary text-decoration-underline">
                <FaChevronLeft size={20} />
                Back
              </span>
            </Link>
          </div>
        </div>
        <div className="row">
          {task?.map((task, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="card" key={index}>
                <img
                  src={task?.logo}
                  alt="Card image"
                  className="img-fluid card_image_task"
                />
                <div className="card-body">
                  <div className="d-flex flex-column gap-1">
                    <h5 className="card-title">{task?.appName}</h5>
                    <p className="card-text">{task?.description}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <button className="btn btn-primary">
                      {task?.coinValue} Coins
                    </button>
                    <div className="d-flex justify-content-end align-items-center gap-2">
                    <span>
                      <Link
                        to={{
                          pathname: `/AdvertisementPanel/Edittask`,
                          search: `?id=${task?._id}`,
                        }}
                      >
                        <MdModeEditOutline size={30} />
                      </Link>
                    </span>
                    <span className="text-danger">
                      <MdDeleteOutline
                        size={30}
                        onClick={() => {
                          deleteTask(task?._id);
                        }}
                      />
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Listtask;
