import React from "react";
import "./AboutUs_Main.css";
import M1 from "./AboutAssests/images/Media1.webp";
import M2 from "./AboutAssests/images/Media2.webp";
import M3 from "./AboutAssests/images/Media3.webp";
import M4 from "./AboutAssests/images/Media4.webp";
import M5 from "./AboutAssests/images/Media5.webp";

const AboutUs_Media_08 = () => {
  return (
    <div className="container">
      <div className="row text-center">
        <div className="col-lg-2 col-md-12 col-12"></div>
        <div className="col-lg-9">
          <h1 className="fw-bold ">For Media</h1>
          <br />
          <p className="fs-5 ALIGN_JUST">
            Pixclick is always happy to bring light on what is happening in the
            marketing and SEO industry, as well as being a trusted data
            provider. Pixclick as a company was covered by largest media outlets
            and our data on online behaviour supports data journalism around the
            world. For more info check our
            <a className="fw-bold" href="">
              {" "}
              Data Provider Page
            </a>
          </p>

          <p className="fs-5 fw-bold">
            For any media related queries, please, contact the team at{" "}
            <span>
              <a href="">@Pixclick.com</a>
            </span>
          </p>
        </div>
      </div>

      <div className="row mt-3 text-center">
        <div className="col-lg-2 col-md-4 col-12 Mediasize123">
          <img src={M1} alt="" className="img-fluid" />
        </div>

        <div className="col-lg-3 col-md-4 col-12 mt-3 Mediasize123">
          <img src={M2} alt="" className="img-fluid" />
        </div>

        <div className="col-lg-2 col-md-4 col-12 Mediasize123">
          <img src={M3} alt="" className="img-fluid" />
        </div>

        <div className="col-lg-2 col-md-4 col-12 Mediasize123">
          <img src={M4} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-1 col-md-4 col-12 "></div>
        <div className="col-lg-2 col-md-4 col-12 Mediasize123">
          <img src={M5} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs_Media_08;
