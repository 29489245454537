import React, { useEffect, useState } from "react";
import "./Profileview.css";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import earnings from "../../../Assets/image (5).png";
import cash from "../../../Assets/image (6).png";
import follower from "../../../Assets/image (3).png";
import click from "../../../Assets/image (4).png";
import url from "../../../Assets/image (1).png";
import like from "../../../Assets/image (2).png";
import { Getsingleadvertiser } from "../../../Api/API_List/Advertiser";
import { getAdvertiserId } from "../../../Utils/Storage";
import Header from "../../../Components/Header/Header";
import { MdModeEdit } from "react-icons/md";

const Profileview = () => {
  const [advertiserData, setAdvertiserData] = useState("");
  const getAdvertiserDetails = () => {
    const id = getAdvertiserId();
    Getsingleadvertiser(id)
      .then((res) => {
        setAdvertiserData(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAdvertiserDetails();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 USER_VIEW_CONTAINER">
            <div className="d-flex justify-content-between">
              <Link
                to="/"
                className="back-button"
                style={{ textDecoration: "none" }}
              >
                <FaChevronLeft size={30} /> Back
              </Link>
              <Link
                to="/Myprofile/Profileedit"
                className="edit-button d-flex align-items-center"
                style={{ textDecoration: "none" }}
              >
                <MdModeEdit size={30} />
              </Link>
            </div>
            <div className="text-center mb-4">
              <img
                className="profile_img"
                src={advertiserData.profileUrl}
                alt="Profile_Image"
              />
            </div>
            <div className="row mt-3">
              <div className="col-md-12 HEAD_BOXES d-flex flex-wrap">
                <div className="card_box">
                  <h6 className="card-title">Clicks</h6>
                  <p>
                    <img src={click} alt="post" className="img-fluid " />{" "}
                    {advertiserData.totalClickCount}
                  </p>
                </div>
                <div className="card_box">
                  <h6 className="card-title">URL Posted</h6>
                  <p>
                    <img src={url} alt="post" className="img-fluid " />{" "}
                    {advertiserData.postCount}
                  </p>
                </div>
                <div className="card_box">
                  <h6 className="card-title">Task Posted</h6>
                  <p>
                    <img src={cash} alt="post" className="img-fluid " />{" "}
                    {advertiserData.taskCount}
                  </p>
                </div>
                <div className="card_box">
                  <h6 className="card-title">Coin Value</h6>
                  <p>
                    <img src={earnings} alt="post" className="img-fluid " />{" "}
                    {advertiserData.coinValue}
                  </p>
                </div>
                <div className="card_box">
                  <h6 className="card-title">Likes</h6>
                  <p>
                    <img src={like} alt="post" className="img-fluid " />{" "}
                    {advertiserData.allLikesCount}
                  </p>
                </div>
                <div className="card_box">
                  <h6 className="card-title">Followers</h6>
                  <p>
                    <img src={follower} alt="post" className="img-fluid " />{" "}
                    {advertiserData.followersCount}
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-3 mx-5">
              <div className="col-md-6">
                <h5>Name</h5>
                <p>{advertiserData.name}</p>
              </div>
              <div className="col-md-6">
                <h5>Advertiser Name</h5>
                <p>{advertiserData.userName}</p>
              </div>
            </div>
            <div className="row mt-3 mx-5">
              <div className="col-md-6">
                <h5>Mobile Number</h5>
                <p>{advertiserData.mobileNumber}</p>
              </div>
              <div className="col-md-6">
                <h5>Email</h5>
                <p>{advertiserData.email}</p>
              </div>
            </div>
            <div className="row mt-3 mx-5">
              <div className="col-md-6">
                <h5>Company Name</h5>
                <p>{advertiserData.companyName}</p>
              </div>
              <div className="col-md-6">
                <h5>Referral Code or Not</h5>
                <p>{advertiserData.referralCode}</p>
              </div>
            </div>
            <div className="row mt-3 mx-5">
              <div className="col-md-6">
                <h5>Completed Task</h5>
                <p>{advertiserData.completeTaskOnUser}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profileview;
