import React from 'react'

const Feed = () => {
  return (
    <div>
      Hi feed
    </div>
  )
}

export default Feed

