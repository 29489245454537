import React from "react";
import "./Features.css";

const FeaturesTrialcard = () => {
  return (
    <div className="container ">
      <div className="row ">
        <div className="col-lg-12 md-12 col-12 ">
          <div class="card w-100  mb-3 rounded-4">
            <div class="card-body background_features_trialcard123 rounded-4">
              <h5 class="card-title fs-1 fw-bold text-center pt-5 text-white mb-5">
                Get it all in one with Pixclick
              </h5>

              <div className="button_forfeatures1trial pb-5 text-center">
                <button type="button" class="btn  px-3 py-3 fw-bold">
                <a href="/" className="text-white">Get Started For Free</a>  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesTrialcard;
