import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home_Main.css";

const testimonialData = [
  {
    name: "Ester Gimenez",
    position: "Senior SEO & UX Consultant",
    source: "Pixclick G2 reviews",
    image:
      "https://cdn.semrush.com/static/index/testimonials/Barbora_Jassova.c7f3ffbab758.jpg",
  },
  {
    name: "Sarojal linda",
    position: "Performance Marketing Specialist, Banco del Sol",
    source: "Pixclick G2 reviews",
    image:
      "https://static.wixstatic.com/media/65fa46_1a0037befa224b73bbc429c3952de184~mv2.png/v1/fill/w_258,h_258,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Cassandre%20Profile%20image%20round.png",
  },
  {
    name: "Mesmer Doue ",
    position: "Digital Marketing Consultant, Blue Lance",
    source: "Pixclick G2 reviews",
    image:
      "https://cdn.semrush.com/static/index/testimonials/Mesmer_Doue.41fd340e9aac.jpg",
  },
];

const Home_customer = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  return (
    <div className="container customer_bg_123 mt-0 rounded-5">
      <div className="row text-center">
        <div className="col-lg-2 md-12 col-12"></div>
        <div className="col-lg-8 md-12 col-12">
          <h1 className="fw-bold mt-5">
            “Pixclick is like a keyword research tool, Google Trends, Moz,
            Hootsuite and SimilarWeb in one.”
          </h1>
          <Slider {...settings}>
            {testimonialData.map((testimonial, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center"
              >
                <img
                  src={testimonial.image}
                  className="img-fluid mt-5"
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <p className="fw-bold fs-5 mt-2">{testimonial.name}</p>
                <p>{testimonial.position}</p>
                <p className="text-secondary">Source: {testimonial.source}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Home_customer;
