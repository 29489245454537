import React from 'react'

const Home_Final_05  = () => {
  return (
    <div className="container-fluid w-100">
      <div className="row ">
        <div className="col-lg-12 md-12 col-12 ">
          <div class="card w-100 rounded-5 shadow-lg">
            <div class="card-body background_features_trialcard123 w-100 rounded-5 shadow-lg ">
              <h1 class="card-title fs-1 fw-bold text-center mt-3 text-white">
                Get Started with Pixclick today
              </h1><br/>
              <p className="fs-5 text-white text-center">Get 7 days of full access to all toolkits. Cancel anytime.</p>

              <div className="button_forfeatures1trial mt-5 text-center">
                <button type="button" class="btn  px-5 py-3 fw-bold">
                  Start your free trial
                </button><br />
              </div>
            </div>
          </div><br />
        </div>
      </div>
    </div>
  )
}

export default Home_Final_05 
