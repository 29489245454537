import React, { useState } from "react";
import "./Features.css";

const FeaturesCards = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const openTab = (tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(tabName).style.display = "block";
    setActiveTab(tabName);
    document.getElementById(tabName + "-tab").classList.add("active");
  };

  // This useEffect ensures that when the component mounts,
  // Tab1 is displayed and marked as active.
  React.useEffect(() => {
    openTab("Tab1");
  }, []);

  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h2 className="fw-bold ">Choose your area of interest</h2>
            <br />
            <div>
              <div className="col-12 change_scroll_content pt-3">
                <div className="wholecontainertab d-flex justify-content-between ">
                  <span className="mytab_123">
                    <button
                      id="Tab1-tab"
                      className={`tablinks ${
                        activeTab === "Tab1" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab1")}
                      style={{
                        backgroundColor: activeTab === "Tab1" ? "black" : "",
                      }}
                    >
                      <span className="text-white  px-2 "> All</span>
                    </button>
                  </span>

                  <span className="mytab_123">
                    <button
                      id="Tab2-tab"
                      className={`tablinks ${
                        activeTab === "Tab2" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab2")}
                      style={{
                        backgroundColor: activeTab === "Tab2" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">SEO</span>
                    </button>
                  </span>

                  <span className="mytab_123">
                    <button
                      id="Tab3-tab"
                      className={`tablinks ${
                        activeTab === "Tab3" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab3")}
                      style={{
                        backgroundColor: activeTab === "Tab3" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">Content</span>
                    </button>
                  </span>

                  <span className="mytab_123">
                    <button
                      id="Tab4-tab"
                      className={`tablinks ${
                        activeTab === "Tab4" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab4")}
                      style={{
                        backgroundColor: activeTab === "Tab4" ? "black" : "",
                      }}
                    >
                      <span className="text-white  "> Market Research</span>
                    </button>
                  </span>

                  <span className="mytab_123">
                    <button
                      id="Tab5-tab"
                      className={`tablinks ${
                        activeTab === "Tab5" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab5")}
                      style={{
                        backgroundColor: activeTab === "Tab5" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">Advertising</span>
                    </button>
                  </span>

                  <span className="mytab_123">
                    <button
                      id="Tab6-tab"
                      className={`tablinks ${
                        activeTab === "Tab6" ? "active" : ""
                      }`}
                      onClick={() => openTab("Tab6")}
                      style={{
                        backgroundColor: activeTab === "Tab6" ? "black" : "",
                      }}
                    >
                      <span className="text-white  ">Social Media</span>
                    </button>
                  </span>
                </div>
              </div>

              {/* tab-content */}

              <div className="">
                <div id="Tab1" className="tabcontent ">
                  <div className="container">
                    <div className="row gap-5 mt-5 d-flex justify-content-around">
                      {/* card 1-------------------------------- row 1 */}
                      <div
                        className="col-lg-4 col-md-4   card pt-2"
                        style={{ width: "25rem" }}
                      >
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Keyword Research
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Bring traffic to your site by finding the best
                            Keyboards
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              6 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 1-------------------------------- row 1 */}

                      {/* card 2-------------------------------- row 1 */}
                      <div
                        className=" col-lg-4 col-md-4 col-sm-4 card pt-2"
                        style={{ width: "25rem" }}
                      >
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            On-page SEO
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 2-------------------------------- row 1 */}

                      <div
                        className=" col-lg-4 col-md-4 col-sm-4 card pt-2"
                        style={{ width: "25rem" }}
                      >
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Competitor Analysis
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="card pt-2 " style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Content Marketing
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Rank Tracking
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Learn SEO
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Social Media Management
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Rank Tracking
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Link Building
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Competitor SEO Analysis
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Content Creation 
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Paid Advertisting
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Competitor PR Monitoring
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Website Monetization
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>

                {/* Tab-2  */}

                <div id="Tab2" className="tabcontent">
                  <div>
                    <div className="d-flex flex-wrap justify-content-around pt-4 gap-5">
                      {/* card 1-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Rank Tracking
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Bring traffic to your site by finding the best
                            Keyboards
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              6 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 1-------------------------------- row 1 */}

                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Link Building
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Competitor SEO Analysis
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Keyword Research
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Local SEO
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            PPC Keyword Research
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Bring traffic to your site by finding the best
                            Keyboards
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              6 tools
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div id="Tab3" className="tabcontent">
                  <div>
                    <div className="d-flex  flex-wrap justify-content-around pt-4 gap-5">
                      {/* card 1-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Content Marketing
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Bring traffic to your site by finding the best
                            Keyboards
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              6 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 1-------------------------------- row 1 */}

                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Content Creation 
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Content Optimization
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Content Optimization
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div id="Tab4" className="tabcontent">
                  <div>
                    <div className="d-flex  flex-wrap justify-content-around pt-4 gap-5">
                      {/* card 1-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Competitor Analysis
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Bring traffic to your site by finding the best
                            Keyboards
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              6 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 1-------------------------------- row 1 */}

                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Market Analysis
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Paid Advertising
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Market Advertising
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div id="Tab5" className="tabcontent">
                  <div>
                    <div className="d-flex  flex-wrap justify-content-around pt-4 gap-5">
                      {/* card 1-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            PPC Keyword Reseach
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Build your perfect paid search campign with our
                            keyboard resaerch tools
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              6 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 1-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Website Monetization
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Monetize your audience with our powerful tools for
                            bloggers, ad publishers, and affilliate managers
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            Website Monetization
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>



                      {/* card 2-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                           Monetization
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Get new ideas to improve your site's health and SEO
                            performance
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div id="Tab6" className="tabcontent">
                  <div>
                    <div className=" pt-4 mt-4 ">
                      {/* card 1-------------------------------- row 1 */}
                      <div className="card pt-2" style={{ width: "25rem" }}>
                        <div className="card-body cardheadingoverforcards11">
                          <h5 className="card-title fw-bold fs-4 ">
                            SOCIAL MEDIA Management
                          </h5>
                          <h6 className="card-subtitle mt-3 text-body-secondary">
                            Bring traffic to your site by finding the best
                            Keyboards
                          </h6>
                          <br />
                          <br />
                          <br />
                          <div className="learnmore_colorfor_css">
                            <a href="#" className="card-link fw-bold">
                              Learn more
                            </a>
                            <span className="float-end cardtoolsdesign">
                              2 tools
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* card 1-------------------------------- row 1 */}

                      {/* card 2-------------------------------- row 1 */}

                      {/* card 2-------------------------------- row 1 */}
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <br />
              </div>

              {/* 2nd row */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeaturesCards;
