import React from "react";

import "./Home_Main.css";
import dd from "../Home/Home_Assets/images/greenimg.svg";
import dd2 from "../Home/Home_Assets/images/raja.jpg";

const Home_Thirdcomponents_03 = () => {


  return (
    <>
      <div className="container mt-5 ">
        <div className="row ">
          <div className="col-lg-5 col-md-6 col-12 my-5 py-5">
            <h1 className="fw-bold  fs-1 ">More data. More insights</h1>

            <p className="fs-5  ">
              With the size of our SEO databases and speed of our backlink
              crawler, imagine what you can achieve if you have the insights
              your competitors don’t.
            </p>
            <button className="px-5  py-2 my-4 background_color_thirdcomponents border-0 rounded-1">
              Learn More
            </button>
          </div>
          <div className="col-lg-1 col-md-12 col-12"></div>
          <div className="col-lg-6 col-md-6 col-12 position-relative card_image_moresights">
            <img
              src={dd}
              alt="ss"
              className="img-fluid card-img"
              
            />

            <div className="d-flex">
              {/* Top Two Cards */}
              <div className="top_card ">
                <div
                  className="card card-sm position-absolute top-0 start-50 mt-5 d-flex align-items-center "
                  // style={{ width: "180px", height: "140px" }}
                >
                  <div className="card-body ">
                    <h2 className="card-title fw-bold cardsize_thirdcomponents">
                      25B
                    </h2>
                    <p className="card-text">keywords</p>
                  </div>
                </div>

                <div
                  className="card card-sm position-absolute top-0 my-5 start-30  d-flex align-items-center"
                  // style={{ width: "190px", height: "140px" }}
                >
                  <div className="card-body">
                    <h2 className="card-title fw-bold cardsize_thirdcomponents">
                      43T
                    </h2>
                    <p className="card-text">backlinks</p>
                  </div>
                </div>
              </div>

              {/* Bottom Two Cards */}
              <div
                className="card card-sm position-absolute top-50 start-50 d-flex align-items-center"
                // style={{ width: "190px", height: "140px" }}
              >
                <div className="card-body">
                  <h2 className="card-title fw-bold cardsize_thirdcomponents">
                    80
                  </h2>
                  <p className="card-text">domain profiles</p>
                </div>
              </div>

              <div
                className="card card-sm position-absolute top-50 start-55 d-flex align-items-center"
                // style={{ width: "190px", height: "140px" }}
              >
                <div className="card-body">
                  <h2 className="card-title fw-bold cardsize_thirdcomponents">
                    140
                  </h2>
                  <p className="card-text">geo databases</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <img src={dd2} className="img-fluid ceoimagesizereduce" ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mt-5 ">
            <h1 className="mt-5  fs-1 fw-bold">Our CEO will eat his hat</h1>
            <p className="text-start fs-5">
             <i>If you find another tool that lets you do all these things</i> 
            </p>
            <ul className="text-start point1 lh-3">
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Access over 25
                billion keywords for 130 countries
              </li>
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Run in-depth website
                audit based on 130+ checks
              </li>
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Get recommendations
                to improve your content to <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                increase search rankings
              </li>
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Track and analyze
                competitor websites and marketing <br />{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;strategies
              </li>
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Create and track
                your PPC campaigns
              </li>
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Draft, schedule and
                post content on social
              </li>
              <li className="lh-lg fs-5">
                <i class="bi bi-check-lg tick fw-bold"></i> Create and schedule
                white-labeled or branded reports
              </li>
            </ul>
            <button className="px-5  py-2 my-4 background_color_thirdcomponents border-0 rounded-1">
              Discover all Pixclick features
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home_Thirdcomponents_03;
