import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home_Main from "./Pages/Home/Home_Main";
import Features_Main from "./Pages/Features/Features_Main";
import AboutUs_Main from "./Pages/AboutUs/AboutUs_Main";
import Feed from "./Pages/Feed/Feed";
import AdvertisementPanel from "./AdvertisementPanel/AdvertisementPanel";
import Dashboard from "./AdvertisementPanel/Pages/Dashboard/Dashboard";
import Create_Post from "./AdvertisementPanel/Pages/Posts/Create_Post/Create_Post";
import Welcome_Page from "./Pages/Login/Welcome_Page/Welcome_Page";
import Login_Page from "./Pages/Login/Login_Page/Login_Page";
import SignUp_Page_1 from "./Pages/Login/SignUp_Pages/SignUp_Page_1";
import SignUp_Page_2 from "./Pages/Login/SignUp_Pages/SignUp_Page_2";
import SignUp_Page_3 from "./Pages/Login/SignUp_Pages/SignUp_Page_3";
import OTP_Verify from "./Pages/Login/OTP_Verify/OTP_Verify";
import Privacy_Policy from "./Components/Privacy_Policy/Privacy_Policy";
import Forgetpassword from "./Pages/Login/Forget_Password/Forgetpassword";
import Profileview from "./Pages/Advertiserprofile/Profileview/Profileview";
import Profileedit from "./Pages/Advertiserprofile/Profileedit/Profileedit";
import Cancellation_policy from "./Components/Cancellation_policy/Cancellation_policy";
import Terms_And_Conditions from "./Components/Terms_And_Conditions/Terms_And_Conditions";
import Coins_Policy from "./Components/Coins_Policy/Coins_Policy";
import Listpost from "./AdvertisementPanel/Pages/Posts/Listpost/Listpost";
import Createtask from "./AdvertisementPanel/Pages/Tasks/Createtask/Createtask";
import Createaccount from "./AdvertisementPanel/Pages/Accounts/Createaccount/Createaccount";
import Listtask from "./AdvertisementPanel/Pages/Tasks/Listtask/Listtask";
import Listaccount from "./AdvertisementPanel/Pages/Accounts/Listaccount/Listaccount";
import Home from "./component_pixalivegroup_campagion/Home/Home";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home_Main />,
  },
  {
    path: "/Feed",
    element: <Feed />,
  },
  {
    path: "/Features_Main",
    element: <Features_Main />,
  },
  {
    path: "/AboutUs_Main",
    element: <AboutUs_Main />,
  },
  {
    path: "/Welcome_Page",
    element: <Welcome_Page />,
  },
  {
    path: "/Login_Page",
    element: <Login_Page />,
  },
  {
    path: "/SignUp_Page_1",
    element: <SignUp_Page_1 />,
  },
  {
    path: "/SignUp_Page_2",
    element: <SignUp_Page_2 />,
  },
  {
    path: "/SignUp_Page_3",
    element: <SignUp_Page_3 />,
  },
  {
    path: "/OTP_Verify",
    element: <OTP_Verify />,
  },
  {
    path: "/Privacy_Policy",
    element: <Privacy_Policy />,
  },
  {
    path: "/Cancellation_policy",
    element: <Cancellation_policy />,
  },
  {
    path: "/Terms_And_Conditions",
    element: <Terms_And_Conditions />,
  },
  {
    path: "/Coins_policy",
    element: <Coins_Policy />,
  },
  {
    path: "/Forget_Password",
    element: <Forgetpassword />,
  },
  {
    path: "/Myprofile",
    element: <Profileview />,
  },
  {
    path: "/Myprofile/Profileedit",
    element: <Profileedit />,
  },
  {
    path: "/AdvertisementPanel",
    element: <AdvertisementPanel />,
    children: [
      {
        path: "/AdvertisementPanel",
        element: <Dashboard />,
      },
      {
        path: "/AdvertisementPanel/Create_Post",
        element: <Create_Post />,
      },
      {
        path: "/AdvertisementPanel/Allpostsview",
        element: <Listpost />,
      },
      {
        path: "/AdvertisementPanel/Create_Task",
        element: <Createtask />,
      },
      {
        path: "/AdvertisementPanel/Alltasksview",
        element: <Listtask />,
      },
      {
        path: "/AdvertisementPanel/Create_Account",
        element: <Createaccount />,
      },
      {
        path: "/AdvertisementPanel/Listaccount",
        element: <Listaccount />,
      },
    ],
  },

  {
    path: "vendors-call-out",
    element: <Home />,
  },




]);

function App() {
  return <RouterProvider router={appRouter} />;
}

export default App;
