import React from "react";
import "./Welcome_Page.css";
import Welcome_Page_Cover_Pix from "../../../Assets/Welcome_Page_Cover_Pix.png";
import Welcome_Page_Phone_Icon from "../../../Assets/Welcome_Page_Phone_Icon.png";
import Welcome_Page_SignUp_Icon from "../../../Assets/Welcome_Page_SignUp_Icon.png";
import { NavLink } from "react-router-dom";

const Welcome_Page = () => {
  return (
    <>
      <div className="overflow-x-hidden overflow-y-hidden">
        <div className="row">
          <div className="col-lg-6 LEFT_SIDE_AREA img-fluid">
            <div className="LEFT_CONTAINER">
              <img
                src={Welcome_Page_Cover_Pix}
                className="img-fluid WELCOME_PAGE_COVER_PIX "
                alt="Welcome_Page_Cover_Pix"
              />
            </div>
          </div>
          <div className="col-lg-6 px-5 py-5">
            <div className="RIGHT_SIDE_AREA align-items-center">
              <p className="LOGIN_HEADING_RIGHT_SIDE_AREA align-items-center">
                Login in to
                <p className="fw-bold LOGO_NAME_RIGHT_SIDE_AREA">Pixclick</p>
              </p>
              <NavLink to="/loginpage">
                <span className="LOGIN_BUTTON d-flex align-items-center justify-content-center gap-2">
                  <img
                    src={Welcome_Page_Phone_Icon}
                    className="img-fluid WELCOME_PAGE_PHONE_ICON"
                    alt="Welcome_Page_Phone_Icon"
                  />
                  Login with E-mail
                </span>
              </NavLink>

              <NavLink to="/SignUp_Page_1">
                <p className="SIGNUP_PATH align-items-center mt-5">
                  Don't have a account?
                  <p className="SIGNUP_BUTTON d-flex align-items-center justify-content-center gap-1">
                    <img
                      src={Welcome_Page_SignUp_Icon}
                      className="img-fluid WELCOME_PAGE_SIGNUP_ICON"
                      alt="Welcome_Page_SignUp_Icon"
                    />
                    Sign Up
                  </p>
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome_Page;
