import React, { useEffect, useState } from "react";
import "./Profileedit.css";
import Header from "../../../Components/Header/Header";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { BiPencil } from "react-icons/bi";
import { getAdvertiserId } from "../../../Utils/Storage";
import {
  Getsingleadvertiser,
  UpdateAdvertiser,
} from "../../../Api/API_List/Advertiser";
import { uploadFile } from "../../../Utils/Fileupload";
import { isValidEmail, isValidPhone } from "../../../Utils/Validation";
import earnings from "../../../Assets/image (5).png";
import cash from "../../../Assets/image (6).png";
import follower from "../../../Assets/image (3).png";
import click from "../../../Assets/image (4).png";
import url from "../../../Assets/image (1).png";
import like from "../../../Assets/image (2).png";

const Profileedit = () => {
  let intialstateerror = {
    name: { required: false },
    email: { required: false, valid: false },
    password: { required: false },
    confirmPassword: { required: false },
    mobileNumber: { required: false },
    companyName: { required: false },
    referralCode: { required: false },
  };

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState(intialstateerror);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const getAdvertiserDetails = () => {
    const id = getAdvertiserId();
    Getsingleadvertiser(id)
      .then((res) => {
        setInputs(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAdvertiserDetails();
  }, []);

  const handleValidation = (data) => {
    const error = intialstateerror;
    if (data.name === "") {
      error.name.required = true;
    }
    if (data.password === "") {
      error.password.required = true;
    }
    if (data.confirmPassword === "") {
      error.confirmPassword.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (data.companyName === "") {
      error.companyName.required = true;
    }
    if (data.referralCode === "") {
      error.referralCode.required = true;
    }

    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber.valid = true;
    }
    return error;
  };

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    const folder = "advertisers/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, profileUrl: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEditClick = () => {
    const fileInput = document.getElementById("file-upload");
    fileInput.click();
  };

  const handleUpdateAdvertiser = (event) => {
    event.preventDefault();
    const newerror = handleValidation(inputs);
    setErrors(newerror);
    setSubmitted(true);
    const allInputs = Object.values(newerror);
    const valid = allInputs.some((x) => x === true);
    if (!valid) {
      UpdateAdvertiser(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/Myprofile");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <form
          className="form col-lg-10 offset-lg-1"
          onSubmit={handleUpdateAdvertiser}
        >
          <div className="row">
            <div className="col-12">
              <div>
                <Link
                  to={"/Myprofile"}
                  className="text-primary text-decoration-none d-flex align-items-center gap-2"
                  style={{ textDecoration: "none" }}
                >
                  <FaChevronLeft />
                  Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="preview-container position-relative">
                <img
                  src={inputs?.profileUrl}
                  width="200"
                  height="150"
                  alt="Preview"
                  className="preview-image profile_img"
                />
                <div className="edit_icon" onClick={handleEditClick}>
                  <BiPencil size={30} />
                </div>
              </div>
              <input
                className="file-upload mt-2 profile_img"
                onChange={handleFileInputs}
                name="profileUrl"
                id="file-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 HEAD_BOXES d-flex flex-wrap">
              <div className="card_box">
                <h6 className="card-title">Clicks</h6>
                <p>
                  <img src={click} alt="post" className="img-fluid " />{" "}
                  {inputs?.totalClickCount ? inputs?.totalClickCount : 0}
                </p>
              </div>
              <div className="card_box">
                <h6 className="card-title">URL Posted</h6>
                <p>
                  <img src={url} alt="post" className="img-fluid " />{" "}
                  {inputs.postCount ? inputs.postCount : 0}
                </p>
              </div>
              <div className="card_box">
                <h6 className="card-title">Task Posted</h6>
                <p>
                  <img src={cash} alt="post" className="img-fluid " />{" "}
                  {inputs.taskCount ? inputs.taskCount : 0}
                </p>
              </div>
              <div className="card_box">
                <h6 className="card-title">Coin Value</h6>
                <p>
                  <img src={earnings} alt="post" className="img-fluid " />{" "}
                  {inputs.coinValue ? inputs.coinValue : 0}
                </p>
              </div>
              <div className="card_box">
                <h6 className="card-title">Likes</h6>
                <p>
                  <img src={like} alt="post" className="img-fluid " />{" "}
                  {inputs.allLikesCount ? inputs.allLikesCount : 0}
                </p>
              </div>
              <div className="card_box">
                <h6 className="card-title">Followers</h6>
                <p>
                  <img src={follower} alt="post" className="img-fluid " />{" "}
                  {inputs.followersCount ? inputs.followersCount : 0}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-lg-6 ">
              <div>
                <div className="d-flex flex-column mt-3">
                  <label
                    className="form-label text-black font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter name"
                    className="form-control border w-100"
                    name="name"
                    value={inputs?.name}
                    onChange={handleInputs}
                  />
                </div>
                <div className="d-flex flex-column mt-3">
                  <label
                    className="form-label text-black font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Mobile Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Mobile Number"
                    className="form-control border w-100"
                    name="mobileNumber"
                    value={inputs?.mobileNumber}
                    onChange={handleInputs}
                  />
                </div>
                <div className="d-flex flex-column mt-3">
                  <label
                    className="form-label text-black font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Password<span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="form-control border w-100"
                    name="password"
                    value={inputs?.password}
                    onChange={handleInputs}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div>
                <div className="d-flex flex-column mt-3">
                  <label
                    className="form-label text-black font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Company Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter user name"
                    className="form-control border w-100"
                    name="companyName"
                    value={inputs?.companyName}
                    onChange={handleInputs}
                  />
                </div>
                <div className="d-flex flex-column mt-3">
                  <label
                    className="form-label text-black font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Enter the email"
                    className="form-control border w-100"
                    name="email"
                    value={inputs?.email}
                    onChange={handleInputs}
                  />
                </div>

                <div className="d-flex flex-column mt-3">
                  <label
                    className="form-label text-black font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Referral Code or Not<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter referral code"
                    className="form-control border w-100"
                    name="referralCode"
                    value={inputs?.referralCode}
                    onChange={handleInputs}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
              <Link to={"/Advertiserdashboard/Advertisermanagement"}>
                <button className="btn btn-danger ms-3">Cancel</button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Profileedit;
