import React, { useState } from "react";
import "./SignUp_Page.css";
import { NavLink } from "react-router-dom";
import SignUp_Page_Cover_Pix from "../../../Assets/SignUp_Page_Cover_Pix.png";
import Signup_Page_Next_Icon from "../../../Assets/Signup_Page_Next_Icon.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isValidEmail, isValidPhone } from "../../../Utils/Validation";
import { getToken, saveToken } from "../../../Utils/Storage";

const SignUp_Page_1 = () => {
  // This is for initialStateInputs
  const initialStateInputs = {
    name: "",
    mobileNumber: "",
    email: "",
  };

  // this is for initialStateErrors
  const initialStateErrors = {
    name: { required: false, valid: false },
    mobileNumber: { required: false, valid: false },
    email: { required: false, valid: false },
  };

  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  // This is for handleValidation
  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber.valid = true;
    }

    return error;
  };

  // This is for handleErrors
  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };

  // This is for handleInput
  const handleInput = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });

    if (submitted) {
      const newError = handleValidation({ ...inputs, [name]: value });
      setErrors(newError);
    }
  };

  // This is for handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    console.log("Console result:", inputs);
    setErrors(newError);
    setSubmitted(true);

    if (handleErrors(newError)) {
      if (inputs.email && inputs.mobileNumber && inputs.name) {
        navigate("/SignUp_Page_2", {
          state: {
            email: inputs.email,
            mobileNumber: inputs.mobileNumber,
            name: inputs.name,
          },
        });
        toast.success("signup_1 successfull");;
      } else {
        toast.error("Please fill in all required fields.");
      }
    }
  };

  return (
    <div>
      <div className="overflow-x-hidden overflow-y-hidden">
        <div className="row">
          <div className="col-lg-6">
            <div className="SIGNUP_LEFT_CONTAINER">
              <img
                src={SignUp_Page_Cover_Pix}
                className="img-fluid WELCOME_PAGE_COVER_PIX "
                alt="Welcome_Page_Cover_Pix"
              />
            </div>
          </div>
          <div className="col-lg-6 SIGNUP_RIGHT_SIDE_AREA">
            <span className="SIGNUP_TITLE">Sign Up</span>
            <div className="SIGNUP_FORM_CONTAINER shadow rounded-3">
              <span className="SIGNUP_STEP_TAG">Step 1/3</span>
              <div className="pt-4  d-flex flex-column gap-3">
                <div>
                  <span className="SIGNUP_TITLE_TAG">Full Name</span>
                  <input
                    className="SIGNUP_INPUT"
                    placeholder="Enter Your Full Name"
                    type="text"
                    onChange={handleInput}
                    name="name"
                  />
                  {errors.email.required ? (
                    <div className="text-danger form-text">
                      This field is required.
                    </div>
                  ) : null}
                </div>
                <div>
                  <span className="SIGNUP_TITLE_TAG">Phone Number</span>
                  <input
                    className="SIGNUP_INPUT"
                    placeholder="Enter Your Mobile Number"
                    type="phone"
                    onChange={handleInput}
                    name="mobileNumber"
                  />
                  {errors.mobileNumber.required ? (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  ) : errors.mobileNumber.valid ? (
                    <span className="text-danger form-text">
                      Enter valid Phone Number.
                    </span>
                  ) : null}
                </div>
                <div>
                  <span className="SIGNUP_TITLE_TAG">Email</span>
                  <input
                    className="EMAIL_INPUT"
                    placeholder="Enter Your Email Address"
                    name="email"
                    onChange={handleInput}
                  />
                  {errors.email.required ? (
                    <div className="text-danger form-text">
                      This field is required.
                    </div>
                  ) : errors.email.valid ? (
                    <div className="text-danger form-text">
                      Enter valid Email Id.
                    </div>
                  ) : null}
                </div>
              </div>

              <NavLink to="/SignUp_Page_2">
                <p
                  onClick={handleSubmit}
                  className="LOGIN_BUTTON  d-flex align-items-center justify-content-center gap-2 mt-5"
                >
                  <img
                    src={Signup_Page_Next_Icon}
                    className="img-fluid WELCOME_PAGE_PHONE_ICON"
                    alt="Welcome_Page_Phone_Icon"
                  />
                  Next
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp_Page_1;
