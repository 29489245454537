import React from "react";
import Dashboard_Carousel from "./Dashboard_Carousel/Dashboard_Carousel";
import Dashboard_Chart from "./Dashboard_Chart/Dashboard_Chart";

const Dashboard = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Dashboard_Carousel />
          </div>
          <div className="col-12">
            <Dashboard_Chart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
