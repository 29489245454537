import React from 'react';
import "./AboutUs_Main.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import vision from "../AboutUs/AboutAssests/images/teamvison.jpg";

const AboutUs_subscriptions = () => {
    return (
        <>
        <div className='container mb-5'>
            <h2 className="fw-bold text-center mb-3">Choose Your Plan</h2><br/>
            <div className='row mb-5 justify-content-center gap-5'>
                <div className='col-lg-3'>
                    <div className='card shadow-box '>
                        <div className='card-body'>
                            <h4 className='card-title bg-primary text-white p-3 rounded-3 text-center'>Basic</h4>
                            <div className='d-flex mt-4 justify-content-center'> 
                                <h1 className="mb-3">₹250</h1>
                                <p className='mt-3 text-muted'>/month</p>
                            </div>
                            <button className='btn w-75 d-block mx-auto mb-3 text-white' style={{ backgroundColor: '#078dd5' }}>Subscribe</button>
                            <h6 className='card-text text-dark mt-3'>For newbies and small-size teams:</h6>
                            <ul className="list-unstyled mb-4">
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 5 projects</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 500 keywords to track</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 10,000 results per report</li>
                            </ul>
                            <h6 className='card-text text-dark mt-3'>SEO, PPC, and research tools:</h6>
                            <p className='text-muted'>Competitor analysis, keyword research, website audit, backlink analysis, advertising tools, and more</p>
                        </div>
                    </div>
                </div>

                <div className='col-lg-3'>
                    <div className='card shadow-box'>
                        <div className='card-body'>
                            <h4 className='card-title bg-primary text-white p-3 rounded-3 text-center'>Standard</h4>
                            <div className='d-flex mt-4 justify-content-center'> 
                                <h1 className="mb-3">₹500</h1>
                                <p className='mt-3 text-muted'>/month</p>
                            </div>
                             <button className='btn w-75 d-block mx-auto mb-3 text-white' style={{ backgroundColor: '#078dd5' }}>Subscribe</button>
                            <h6 className='card-text text-dark mt-3'>For agencies and mid-size businesses:</h6>
                            <ul className="list-unstyled mb-4">
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 15 projects</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 1500 keywords to track</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 30,000 results per report</li>
                            </ul>
                            <h6 className='card-text text-dark mt-3'>All basic features plus:</h6>
                            <p className='text-muted'>Content Marketing Toolkit, historical data, multi-location and device tracking, Looker Studio integration, and more</p>
                        </div>
                    </div>
                </div>
              

                <div className='col-lg-3'>
                    <div className='card shadow-box'>
                        <div className='card-body'>
                            <h4 className='card-title bg-primary text-white p-3 rounded-3 text-center'>Premium</h4>
                            <div className='d-flex mt-4 justify-content-center'> 
                                <h1 className="mb-3">₹1000</h1>
                                <p className='mt-3 text-muted'>/month</p>
                            </div>
                             <button className='btn w-75 d-block mx-auto mb-3 text-white' style={{ backgroundColor: '#078dd5' }}>Subscribe</button>
                            <h6 className='card-text text-dark mt-3'>For large agencies and enterprises:</h6>
                            <ul className="list-unstyled mb-4">
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 40 projects</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 5,000 keywords to track</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> 50,000 results per report</li>
                            </ul>
                            <h6 className='card-text text-dark mt-3'>All standard features plus:</h6>
                            <p className='text-muted'>Share of Voice, extended limits, API access, PLA analytics, free migration from third-party tools, and more</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid bg-light w-100 p-5 rounded-5 mt-5 ">
          <div className="row mt-5">
            <div className="col-lg-5 col-md-12 col-12">
              <h1 className="fw-bold aboutuscolorheading ">
                Vision of Pixclick
              </h1>
              <p className="fs-4  mt-5">
                <i>
                  Pixclick aspires to be a global leader in sectors that improve
                  lives and benefit nations, including Social Media, SaaS,
                  Startups, Services, Software Training & Sustainability through
                  value-driven endeavours.
                </i>
              </p>
            </div>
            <div className="col-lg-1 col-md-12 col-12"></div>
            <div className="col-lg-6 col-md-12 col-12">
              <img src={vision} alt="" className="img-fluid rounded-5" />
            </div>
          </div>
        </div>
        </>
    );
};

export default AboutUs_subscriptions;
