import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import AboutUs_Message_1 from "../AboutUs/AboutUs_Message_1";
import AboutUs_iconsbrands_02 from "../AboutUs/AboutUs_iconsbrands_02";
import AboutUs_LeadingBrands_03 from "../AboutUs/AboutUs_LeadingBrands_03";
import AboutUs_PixclickEvalutaion_04 from "../AboutUs/AboutUs_PixclickEvalutaion_04";
import  AboutUs_Severalcountries_05 from "../AboutUs/AboutUs_Severalcountries_05";
import AboutUs_Map_06 from "../AboutUs/AboutUs_Map_06";
import AboutUs_Investors_07 from "../AboutUs/AboutUs_Investors_07";
import AboutUs_Media_08 from "../AboutUs/AboutUs_Media_08";
import AboutUs_cards_09 from "../AboutUs/AboutUs_cards_09";
import AboutUs_subcriptions from "./AboutUs_subcriptions";



const AboutUs_Main = () => {
  return (
    <>
    <Header/>
      <div className="container">
     <AboutUs_Message_1/><br/><br/><br/>
     <AboutUs_subcriptions/><br/><br/>
     <AboutUs_iconsbrands_02/><br/><br/><br/>
      <AboutUs_LeadingBrands_03/><br/><br/>
      </div>
      <div className="container-fluid">
     < AboutUs_Map_06/><br/><br/><br/><br/><br/>
     </div>
     <div className="container ">
     <AboutUs_Investors_07/><br/><br/>
      <AboutUs_Media_08/><br/><br/>
      <AboutUs_cards_09/>
     </div>
      <Footer/>
    </>
  );
};

export default AboutUs_Main;
