import { Advertisertask } from "../EndPoints";
import MainApi from "../MainApi";

export const createTask = (data) => {
  return MainApi.post(`${Advertisertask}/`, data);
};

export const Gettask = (data) => {
  return MainApi.get(`${Advertisertask}/`, data);
};

export const Getsingletask = (data) => {
  return MainApi.get(`${Advertisertask}/getSingleAdvertiserTask`, {
    params: { _id: data },
  });
};

export const Updatetask = (data) => {
  return MainApi.get(`${Advertisertask}/`, data);
};

export const Deletetask = (data) => {
  return MainApi.delete(`${Advertisertask}/`, { params: { _id: data } });
};
