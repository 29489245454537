import React, { useEffect, useState } from "react";
import "./Create_Post.css";
import coin from "../../../../Assets/coin.png";
import DUMMY_PROFILE_PIC from "../../../../Assets/DUMMY_PROFILE_PIC.png";
import WEB_URL from "../../../../Assets/WEB_URL.png";
import MESSAGE_ICON from "../../../../Assets/MESSAGE_ICON.png";
import Category_Icon from "../../../../Assets/Category_Icon.png";
import { getAdvertiserId } from "../../../../Utils/Storage";
import { Getsingleadvertiser } from "../../../../Api/API_List/Advertiser";
import { Advertisercreatepost } from "../../../../Api/API_List/Advertiserpost";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Create_Post = () => {
  const [profile, setProfile] = useState({});

  const getProfileDetails = () => {
    const id = getAdvertiserId();
    Getsingleadvertiser(id)
      .then((res) => {
        setProfile(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  let initialState = {
    advertiserId: getAdvertiserId(),
    url: "",
    description: "",
    coinValue: "",
    likeAmount: "",
    clickAmount: "",
    category: "",
  };

  let initialStateerror = {
    url: { required: false },
    description: { required: false },
    coinValue: { required: false },
    likeAmount: { required: false },
    clickAmount: { required: false },
    category: { required: false },
  };

  const [post, setPost] = useState(initialState);
  const [errors, setErrors] = useState(initialStateerror);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleValidation = (data) => {
    const error = { ...initialStateerror };
    if (data.url === "") {
      error.url.required = true;
    }
    if (data.description === "") {
      error.description.required = true;
    }
    if (data.coinValue === "") {
      error.coinValue.required = true;
    }
    if (data.likeAmount === "") {
      error.likeAmount.required = true;
    }
    if (data.clickAmount === "") {
      error.clickAmount.required = true;
    }
    if (data.category === "") {
      error.category.required = true;
    }
    return error;
  };

  const handleInputs = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...post,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(post);
    setErrors(newError);
    setSubmitted(true);
    const allInputValid = Object.values(newError);
    const valid = allInputValid.some((x) => x.required === true);
    if (!valid) {
      Advertisercreatepost(post)
        .then((res) => {
          toast.success(res?.data?.message);
          setPost(initialState);
          setErrors(initialStateerror);
          setSubmitted(false);
          event.target.reset();
          getProfileDetails();
          navigate("/advertisementpanel/allpostsview");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <span className="CREATE_POST_TITLE">Create Post</span>
            <div className="col-12 d-flex justify-content-end gap-3">
              <span className="COIN_PIC px-3 py-1">
                <img src={coin} alt="" />
                {profile?.coinValue}
              </span>
              <Link to="/advertisementpanel/allpostsview">
                <button className="btn btn-primary">View All Posts</button>
              </Link>
            </div>
          </div>
          {/* -------------------------CREATE_POST_USER_PROFILE--------------------------- */}
          <div className="row">
            <div className="col-12 d-flex gap-3 pt-3">
              <img
                className="DUMMY_PROFILE_PIC"
                src={
                  profile.profileUrl ? profile.profileUrl : DUMMY_PROFILE_PIC
                }
                alt="DUMMY_PROFILE_PIC"
              />
              <div className="CREATE_POST_USER_DETAILS d-flex flex-column gap-1">
                <span className="CREATE_POST_USER_NAME">{profile?.name}</span>
                <span className="CREATE_POST_USER_ID">{profile?.userName}</span>
              </div>
            </div>
          </div>

          {/* -------------------------INPUT_FIELDS_AREA--------------------------- */}
          {/* -------------------------WEB_URL_INPUT--------------------------- */}
          <div className="row">
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={WEB_URL}
                  className="CREATE_POST_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_POST_INPUT_FIELDS_TITLE">Web URL</span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="url"
                  onChange={handleInputs}
                />
                {errors.url.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            {/* -------------------------MESSAGE_INPUT--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={MESSAGE_ICON}
                  className="CREATE_POST_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_POST_INPUT_FIELDS_TITLE">
                  What's on Your Mind
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="description"
                  onChange={handleInputs}
                />
                {errors.description.required && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>

          {/* -------------------------ADD_COINS_FOR_POST--------------------------- */}
          <div className="row">
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={coin}
                  className="CREATE_POST_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_POST_INPUT_FIELDS_TITLE">
                  Add Coins for a Single{" "}
                  <span className="text-danger fw-bold">Post</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="coinValue"
                  onChange={handleInputs}
                />
                {errors.coinValue.required && (
                  <span className="text-danger form-text">
                    This field is required
                  </span>
                )}
              </div>
            </div>

            {/* -------------------------SET_COINS_FOR_A_CLICK--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={coin}
                  className="CREATE_POST_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_POST_INPUT_FIELDS_TITLE">
                  Set Coins for a single{" "}
                  <span className="text-danger fw-bold">Click</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="clickAmount"
                  onChange={handleInputs}
                />
                {errors.clickAmount.required && (
                  <span className="text-danger form-text">
                    This field is required
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* -------------------------ADD_COINS_FOR_LIKE--------------------------- */}
          <div className="row">
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={coin}
                  className="CREATE_POST_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_POST_INPUT_FIELDS_TITLE">
                  Set Coins for a single{" "}
                  <span className="text-danger fw-bold">Like</span>
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <input
                  className="w-100"
                  type="text"
                  name="likeAmount"
                  onChange={handleInputs}
                />
                {errors.likeAmount.required && (
                  <span className="text-danger form-text">
                    This field is required
                  </span>
                )}
              </div>
            </div>

            {/* -------------------------SET_COINS_FOR_A_CLICK--------------------------- */}
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex gap-1">
                <img
                  src={Category_Icon}
                  className="CREATE_POST_INPUT_FIELDS_ICONS"
                  alt="WEB_URL"
                />
                <span className="CREATE_POST_INPUT_FIELDS_TITLE">
                  Category{" "}
                </span>
              </div>
              <div className="INPUT_BOX pt-1">
                <select
                  className="w-100"
                  name="category"
                  onChange={handleInputs}
                >
                  <option className="CATEGORY_OPTION" value="E-Commerce">
                    E-Commerce
                  </option>
                  <option className="CATEGORY_OPTION" value="automobile">
                    Automobile
                  </option>
                  <option className="CATEGORY_OPTION" value="business">
                    Business
                  </option>
                  <option className="CATEGORY_OPTION" value="education">
                    Education
                  </option>
                  <option className="CATEGORY_OPTION" value="entertainment">
                    Entertainment
                  </option>
                  <option className="CATEGORY_OPTION" value="fashion">
                    Fashion
                  </option>
                  <option className="CATEGORY_OPTION" value="fitness">
                    Fitness
                  </option>
                  <option className="CATEGORY_OPTION" value="food">
                    Food
                  </option>
                  <option className="CATEGORY_OPTION" value="gaming">
                    Gaming
                  </option>
                  <option className="CATEGORY_OPTION" value="health">
                    Health
                  </option>
                  <option className="CATEGORY_OPTION" value="learning">
                    Learning
                  </option>
                  <option className="CATEGORY_OPTION" value="sports">
                    Sports
                  </option>
                </select>
                {errors.category.required && (
                  <span className="text-danger form-text">
                    This field is required
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* -------------------------CREATE_POST_BUTTON--------------------------- */}
          <div className="row py-3">
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="CREATE_POST_BUTTON">
                Post Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Create_Post;
