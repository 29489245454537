export const saveToken = (data) => {
  localStorage.setItem("token", data?.token);
  localStorage.setItem("loginType", data?.loginType);
  if (data?.advertiserDetails && data?.advertiserDetails._id) {
    localStorage.setItem("advertiserDetails", data.advertiserDetails._id);
  } else {
    console.log("Advertiser ID not found");
  }
};

export const getLoginType = () => {
  return localStorage.getItem("loginType");
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getAdvertiserId = () => {
  return localStorage.getItem("advertiserDetails");
};

export const clearStorage = () => {
  localStorage.clear();
};
