import React from 'react'
import './Features.css'

const FeaturesTools = () => {
  return (
    
    <div>
          <div className='container'>
              <div className='row'>
                  <div className='col-sm-12'>
                      <h2 className='text-start fs-1 fw-bold'>All tools</h2>
                  </div>
              </div>
          </div>
          <div className='container'>
              <div className='row mt-5'>
                  <div className='col-sm-3'>
                     
                   <ul  className="feature">
                   <h5 className='text-start fw-bold'>SEO</h5>
                      <li className="text-start feature_1"><a href="" >Backlink Analytics</a></li>
                      <li className="text-start feature_1"><a href="" >Backlink Audit Tool</a></li>
                      <li className="text-start feature_1"><a href="" >Backlink Gap</a></li>
                      <li className="text-start feature_1"><a href="" >Bulk Analysis</a></li>
                      <li className="text-start feature_1"><a href="" >Domain Overview</a></li>
                      <li className="text-start feature_1"><a href="" >Google Looker Studio connectors</a></li>
                      <li className="text-start feature_1"><a href="" >Keyword Gap</a></li>
                     
                   </ul>
                     
                  </div>
                  <div className='col-sm-3'>
                     
                   <ul  class="feature mt-4">
             
                      <li className="text-start feature_1"><a href="" >Keyword Magic Tool</a></li>
                      <li className="text-start feature_1"><a href="" >Keyword Overview</a></li>
                      <li className="text-start feature_1"><a href="" >Link Building Tool</a></li>
                      <li className="text-start feature_1"><a href="" >Listing Management</a></li>
                      <li className="text-start feature_1"><a href="" >Log File Analyzer</a></li>
                      <li className="text-start feature_1"><a href="" >My Reports</a></li>
                      <li className="text-start feature_1"><a href="" >On Page SEO checker</a></li>
                     
                   </ul>
                     
                  </div>
                  <div className='col-sm-3'>
                   <ul  class="feature mt-4">
                      <li className="text-start feature_1"><a href="" >Organic Research</a></li>
                      <li className="text-start feature_1"><a href="" >Organic Traffic Insights</a></li>
                      <li className="text-start feature_1"><a href="" >Position Tracking</a></li>
                      <li className="text-start feature_1"><a href="" >Ranks</a></li>
                      <li className="text-start feature_1"><a href="" >Domain Overview</a></li>
                      <li className="text-start feature_1"><a href="" >Site Audit</a></li>
   
                   </ul>
                     
                  </div>
                  <div className='col-sm-3'>
                     
                   <ul  class="feature">
                   <h5 className='text-start  fw-bold'>Content</h5>
                      <li className="text-start feature_1"><a href="" >SEO Content Template</a></li>
                      <li className="text-start feature_1"><a href="" >SEO Writing Assistant</a></li>
                      <li className="text-start feature_1"><a href="" >Topic Research</a></li>
                      <li className="text-start feature_1"><a href="" >Bulk Analysis</a></li>
                      <li className="text-start feature_1"><a href="" >Readability Checker</a></li>
                      <li className="text-start feature_1"><a href="" >Readability Checker</a></li>
                   </ul>
                     
                  </div>
              </div>
              <div className='row mt-5'>
              <div className='col-sm-3'>
                     <ul  class="feature">
                     <h5 className='text-start  fw-bold'>Advertising</h5>
                        <li className="text-start feature_1"><a href="" >Advertising Research</a></li>
                        <li className="text-start feature_1"><a  href="" >PLA Research</a></li>
                        <li className="text-start feature_1"><a href="" >PPC Keyword tool</a></li>
                      </ul>
                    </div>
              <div className='col-sm-3'>
                     <ul  class="feature">
                     <h5 className='text-start  fw-bold'>Competitive Research</h5>
                        <li className="text-start feature_1"><a href="" >Market Explorer</a></li>
                        <li className="text-start feature_1"><a href="" >Traffic Analytics</a></li>
                      </ul>
                    </div>
              <div className='col-sm-3'>
                     <ul  class="feature">
                     <h5 className='text-start  fw-bold'>Agency Growth Kit</h5>
                        <li className="text-start feature_1"><a href="" >Agency Partners Platform</a></li>
                        <li className="text-start feature_1"><a href="" >Bid Finder</a></li>
                        <li className="text-start feature_1"><a href="" >Client Portal</a></li>
                        <li className="text-start feature_1"><a href="" >CRM</a></li>
                      </ul>
                    </div>
              <div className='col-sm-3'>
                     <ul  class="feature">
                     <h5 className='text-start  fw-bold'>Social Media</h5>
                        <li className="text-start feature_1"><a  href="" >Social Media Tools</a></li>
   
                      </ul>
                    </div>
              </div>
          </div>
   
      </div>
  )
}

export default FeaturesTools
