import React, { useEffect, useState } from "react";
import "./Listaccount.css";
import DUMMY_PROFILE_PIC from "../../../../Assets/DUMMY_PROFILE_PIC.png";
import YOUTUBE_ICON from "../../../../Assets/YOUTUBE_ICON.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { get_selling_account_api } from "../../../../Api/API_List/Advertiser_Create_Selling_Account_API";

const Listaccount = () => {
  const [account, setAccount] = useState([]);

  const get_all_post_accounts = () => {
    get_selling_account_api()
      .then((res) => {
        setAccount(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  };

  useEffect(() => {
    get_all_post_accounts();
  }, []);

  return (
    <>
      <div>
        <div>
          <div className="container-fluid LIST_ACCOUNT_PAGE_CONTAINER">
            {/* ALL_VIEW_ACC_TITLE */}
            <div className="row py-3">
              <div className="col-4 d-flex align-items-center BACK_BUTTON_STYLE">
                <Link
                  to="/advertisementpanel/createaccount"
                  className="text-decoration-none text-black"
                >
                  <i class="bi bi-arrow-left fs-5" />
                  <span className="ms-2 fw-bold">Back</span>
                </Link>
              </div>
              <div className="col-8 d-flex align-items-center">
                <span className="fw-bold">List of Accounts</span>
              </div>
            </div>

            <div className="row ">
              {/* ALL_ACC_VIEW_CARD_1 */}
              {account?.map((account, index) => (
                <div key={index} className="col-12 col-lg-6">
                  <div className="ALL_ACC_VIEW_CARD my-3 p-3">
                    {/* ACC_HOLDER_DETAILS */}
                    <div className="ACC_HOLDER_DETAILS d-flex justify-content-between">
                      <div className="ACC_HOLDER_NAME">
                        <img
                          className="DUMMY_PROFILE_PIC"
                          src={account?.image || DUMMY_PROFILE_PIC}
                          alt="DUMMY_PROFILE_PIC"
                        />{" "}
                        <span className="fw-bold">{account?.name}</span>
                      </div>
                      <div className=" d-flex flex-row gap-3 align-items-center">
                        <i class="bi bi-pencil fs-5"></i>
                        <i class="bi bi-trash fs-5"></i>
                      </div>
                    </div>
                    {/* ACC_TYPE_DETAILS */}

                    {account.accountType === "Facebook" && (
                      <div className="ACC_TYPE_DETAILS d-flex gap-2 align-items-center pt-3">
                        <img
                          className="ACC_TYPE_ICON"
                          src={account?.accountLogo}
                          alt="ACC_TYPE_ICON"
                        />{" "}
                        <div className="ACC_SUBCRIBER_DETAILS">
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-people"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Followers:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {account?.followers}
                            </span>
                          </div>
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-eye"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Followings:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {" "}
                              {account?.following}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {account.accountType === "Instagram" && (
                      <div className="ACC_TYPE_DETAILS d-flex gap-2 align-items-center pt-3">
                        <img
                          className="ACC_TYPE_ICON"
                          src={account?.accountLogo}
                          alt="ACC_TYPE_ICON"
                        />{" "}
                        <div className="ACC_SUBCRIBER_DETAILS">
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-people"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Followers:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {account?.followers}
                            </span>
                          </div>
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-eye"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Followings:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {" "}
                              {account?.following}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {account.accountType === "Twitter" && (
                      <div className="ACC_TYPE_DETAILS d-flex gap-2 align-items-center pt-3">
                        {/* <img
                          className="ACC_TYPE_ICON"
                          src={account?.accountLogo}
                          alt="ACC_TYPE_ICON"
                        />{" "} */}
                        <div className="ACC_SUBCRIBER_DETAILS">
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-people"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Followers:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {account?.followers}
                            </span>
                          </div>
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-eye"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Followings:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {" "}
                              {account?.following}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {account.accountType === "YouTube" && (
                      <div className="ACC_TYPE_DETAILS d-flex gap-2 align-items-center pt-3">
                        {/* <img
                          className="ACC_TYPE_ICON"
                          src={account?.accountLogo}
                          alt="ACC_TYPE_ICON"
                        />{" "} */}
                        <div className="ACC_SUBCRIBER_DETAILS">
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-people"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">
                              Subcribers:
                            </span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {account?.subscribe}
                            </span>
                          </div>
                          <div className="ACC_FOLLOWERS">
                            <i class="bi bi-eye"></i>{" "}
                            <span className="ACC_FOLLOWERS_TITLE">Views:</span>{" "}
                            <span className="ACC_FOLLOWERS_INPUT">
                              {" "}
                              {account?.__v}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* ACC_CATEGORY_DATE_ROI_COST */}
                    <div className="ACC_CATEGORY_DATE_ROI_COST pt-3 d-flex justify-content-around">
                      <div className="col-5  CATEGORY_VIEW_CARD d-flex flex-column  text-center">
                        <span className="VIEW_ACC_CATEGORY_TITLE">
                          Category
                        </span>
                        <span className=" VIEW_ACC_CATEGORY_INPUT">
                          {" "}
                          {account?.category}
                        </span>
                      </div>
                      <div className="col-5 CATEGORY_VIEW_CARD d-flex flex-column text-center">
                        <span className="VIEW_ACC_CATEGORY_TITLE">
                          Joined Date
                        </span>
                        <span className=" VIEW_ACC_CATEGORY_INPUT">
                          {account?.dateOfJoining}
                        </span>
                      </div>
                    </div>
                    <div className="ACC_CATEGORY_DATE_ROI_COST pt-3 d-flex justify-content-around">
                      <div className="col-5 CATEGORY_VIEW_CARD d-flex flex-column text-center">
                        <span className="VIEW_ACC_CATEGORY_TITLE">
                          Total Revenue
                        </span>
                        <span className=" VIEW_ACC_CATEGORY_INPUT">
                          ₹ {account?.revenue}
                        </span>
                      </div>
                      <div className="col-5 CATEGORY_VIEW_CARD d-flex flex-column text-center">
                        <span className="VIEW_ACC_CATEGORY_TITLE">
                          Total cost
                        </span>
                        <span className=" VIEW_ACC_CATEGORY_INPUT">
                          ₹ {account?.totalCost}
                        </span>
                      </div>
                    </div>
                    <div className="ACC_CATEGORY_DATE_ROI_COST pt-3 d-flex justify-content-around">
                      <div className="col-5 CATEGORY_VIEW_CARD d-flex flex-column text-center">
                        <span className="VIEW_ACC_CATEGORY_TITLE">Gender</span>
                        <span className=" VIEW_ACC_CATEGORY_INPUT">
                          {" "}
                          {account?.gender}
                        </span>
                      </div>
                      <div className="col-5 CATEGORY_VIEW_CARD d-flex flex-column text-center">
                        <span className="VIEW_ACC_CATEGORY_TITLE">Country</span>
                        <span className=" VIEW_ACC_CATEGORY_INPUT">
                          {" "}
                          {account?.country}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listaccount;
