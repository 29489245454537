import React, { useState } from "react";
import "./AdvertisementPanel.css";
import "../GlobalStyles/GlobalStyles.css";
import { Outlet } from "react-router-dom";
import { MdOutlineSegment } from "react-icons/md";
import PanelHeader from "./PanelHeader/PanelHeader";
import Sidebar from "./Sidebar/Sidebar";
import Right_Sidebar from "./Right_Sidebar/Right_Sidebar";

const AdvertisementPanel = () => {
  const [toggle, setToggle] = useState(false);

  const toggleSidebar = () => {
    setToggle(!toggle);
  };

  return (
    <div className="PAGE_MAIN_BODY">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 col-sm-12 d-flex">
            {" "}
            <Sidebar
              className={toggle ? "LARGE_SIDEBAR_MAIN_BODY" : "SMALL_STYLE"}
            />
            <div className="PAGE_BODY">
              <div className="MAIN_HEADER_CONTAINER">
                {/*----------------------------This is Menu Icon----------------------------------*/}
                <div className="MAIN_HEADER_ICON" onClick={toggleSidebar}>
                  <MdOutlineSegment className="HEADER_SIDEBAR_TOGGLE_ICON" />
                </div>

                <PanelHeader />
              </div>
              <div className="PAGE_BODY_CONTENT DEFAULT_BG">
                <Outlet />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-0">
            <Right_Sidebar />
          </div>
        </div>
      </div>

      {/* <Right_Sidebar /> */}
      {/* <Sidebar /> */}
    </div>
  );
};

export default AdvertisementPanel;
