import React, { useEffect, useState } from "react";
import "./Listpost.css";
import dummyimage from "../../../../Assets/DUMMY_PROFILE_PIC.png";
import { MdAdsClick, MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { FcLike } from "react-icons/fc";
import { FaRegComment } from "react-icons/fa";
import { FaChevronLeft, FaShare } from "react-icons/fa";
import { getAdvertiserId } from "../../../../Utils/Storage";
import { Getsingleadvertiser } from "../../../../Api/API_List/Advertiser";
import {
  Advertiserallposts,
  Advertiserdeletepost,
} from "../../../../Api/API_List/Advertiserpost";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { LinkPreview } from "@dhaiwat10/react-link-preview";

const Listpost = () => {
  const [posts, setPosts] = useState([]);
  const [profile, setProfile] = useState({});

  const getProfileDetails = () => {
    const id = getAdvertiserId();
    Getsingleadvertiser(id)
      .then((res) => {
        setProfile(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPosts = () => {
    const id = getAdvertiserId();
    Advertiserallposts(id)
      .then((res) => {
        setPosts(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPosts();
    getProfileDetails();
  }, []);

  const shortenMessage = (description) => {
    const maxLength = 30;
    if (description.length <= maxLength) {
      return description;
    } else {
      return description.substring(0, maxLength) + "...";
    }
  };

  const shortenUrl = (url) => {
    const maxLength = 25;
    if (url.length <= maxLength) {
      return url;
    } else {
      return url.substring(0, maxLength) + "...";
    }
  };

  const deletePost = (_id) => {
    const id = getAdvertiserId();
    Advertiserdeletepost({ _id, advertiserId: id })
      .then((res) => {
        toast.success(res?.data?.message);
        getPosts();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Link to={"/advertisementpanel/createpost"}>
              <span className="text-primary text-decoration-underline">
                <FaChevronLeft size={20} />
                Back
              </span>
            </Link>
          </div>
        </div>
        <div className="row">
          {posts.map((post, index) => (
            <div key={index} className="col-lg-4 col-sm-12">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="card-title d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={profile?.profileUrl || dummyimage}
                        alt="dummy image"
                        className="img-fluid rounded-circle"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <div className="d-flex flex-column">
                        <span className="text_name_card">
                          <b>{profile?.name}</b>
                        </span>
                        <span className="text-muted text_name_card">
                          {new Date(post.createdOn).toLocaleDateString(
                            "en-US",
                            { day: "numeric", month: "short", year: "numeric" }
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <button className="btn btn-primary btn-sm rounded-5 card_btn_category">
                        {post?.category}
                      </button>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <Link
                        to={{
                          pathname: `/AdvertisementPanel/Editpost`,
                          search: `?id=${post?._id}`,
                        }}
                      >
                        <MdModeEditOutline size={20} />
                      </Link>
                      <MdDeleteOutline
                        size={20}
                        className="text-danger"
                        onClick={() => deletePost(post?._id)}
                      />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>
                      {post?.url && (
                        <div style={{ maxWidth: "400px" }}>
                          <LinkPreview url={post.url} />
                        </div>
                      )}
                      <a href={post?.url} target="_blank" rel="noreferrer">
                        {shortenUrl(post?.url)}
                      </a>
                    </p>
                    <p>{shortenMessage(post?.description)}</p>
                    <div className="d-flex justify-content-around align-items-center">
                      <div>
                        <span className="d-flex align-items-center gap-2">
                          <MdAdsClick size={20} /> {post?.clickCount}
                        </span>
                      </div>
                      <div>
                        <span className="d-flex align-items-center gap-2">
                          <FcLike size={20} /> {post?.likeCount}
                        </span>
                      </div>
                      <div>
                        <span className="d-flex align-items-center gap-2">
                          <FaRegComment size={20} /> {post?.commentsCount}
                        </span>
                      </div>
                      {/* <div>
                        <span className="d-flex align-items-center gap-2">
                          <FaShare size={20} /> {post?.shareCount}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Listpost;
