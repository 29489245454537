import React from "react";
import "./Home_Main.css";
// import Round1 from '../../Pages/Home/Home_Assets/images/fronthomeimg1.svg'

import BDH1 from "../../Pages/Home/Home_Assets/images/brand1.svg";
import BDH2 from "../../Pages/Home/Home_Assets/images/brand2.svg";
import BDH3 from "../../Pages/Home/Home_Assets/images/brand3.svg";
import BDH4 from "../../Pages/Home/Home_Assets/images/brand4.svg";
import BDH5 from "../../Pages/Home/Home_Assets/images/brand5.svg";
import BDH6 from "../../Pages/Home/Home_Assets/images/brand6.svg";
import Home_TabMessage_1 from "./Home_TabMessage_1";

const Home_firstcomponents_01 = () => {
  return (
    <>
      <div className="container-fluid bg_violet  ">
        
        <div className="row d-flex justify-content-between ">
          <div className="col-lg-12 col-md-12  col-12 mt-5 text-center">
            <h1 className="text-light  Home_main_css_first mt-5  text-center">
              Get measurable results <br />
              from online marketing
            </h1>
            <p className="text-light mt-4 fs-4 text-justify">
              Do SEO, content marketing, competitor research, <br />
              PPC and social media marketing from just one platform.
            </p>
            <br />

            <div class="search_container1 d-flex  flex-wrap gap-3">
              <div className="d-flex ">
                <input
                  type="text"
                  class="search-input input_searchmyinput  p-3  px-5  "
                  id="myInput"
                  placeholder="Enter domain Name "
                />
                <select class="dropdown-select dropdown_selectfor1 ">
                  <input type="text" placeholder="country" />
                  <option value="option1">US</option>
                  <option value="option2">Germany</option>
                  <option value="option3">INDIA</option>
                </select>
              </div>
              <div>
                <button className=" mx-2 background_color_thirdcomponents py-3  px-5  border-0 rounded-1">
                  Start Now
                </button>
              </div>
            </div>
            <p className="text-secondary fs-5 mt-5">
              Trusted by the world’s leading brands
            </p>
          </div>
          <div class="col-lg-3 rnd1 align-self-start">
            {/* <img src={Round1} class='img-fluid' /> */}
          </div>
        </div>

        {/* leading brands logos */}
        <div className="container">
  <div className="d-flex flex-wrap justify-content-around align-items-center gap-5">
    <div>
      <img src={BDH1} className="img-fluid pulse-image" alt="" />
    </div>
    <div className="">
      <img src={BDH2} className="img-fluid pulse-image" alt="" />
    </div>
    <div className="">
      <img src={BDH3} className="img-fluid pulse-image" alt="" />
    </div>
    <div className="">
      <img src={BDH4} className="img-fluid pulse-image" alt="" />
    </div>
    <div className="">
      <img src={BDH5} className="img-fluid pulse-image" alt="" />
    </div>
    <div className="">
      <img src={BDH6} className="img-fluid pulse-image" alt="" />
    </div>
  </div>
</div>

      </div>
      <div className="container-fluid bg_violet  ">
        <div className="container ">
          <h1 className="text-center text-white fw-bold">See what's inside</h1>
          <Home_TabMessage_1 />
        </div>
      </div>
    </>
  );
};

export default Home_firstcomponents_01;
