import React from 'react'
import Contact from '../../component_pixalivegroup_campagion/Contact/Contact'
import Header from '../../component_pixalivegroup_campagion/Header/Header'
import Banner from '../../component_pixalivegroup_campagion/Banner/Banner'
import Footer from '../../component_pixalivegroup_campagion/Footer/Footer'

const Home = () => {
  return (
    <div>
     <Header/>
     <Banner/>
    <Contact/>
    <Footer/>
    </div>
  )
}

export default Home
