import React from "react";
import "./Features.css";
import FEATURESIMG from "./FeaturesAssests/featuresimgnew.png";

const Featuresintro1 = () => {
  return (
    <div className="container mt-5">
      <div className="row ">
        <div className="col-lg-5 col-md-12 col-12">
          <h1 className="fw-bold">Pixclick Features</h1>
          <p className="fs-5 mt-4 ">
            Start solving your marketing challenges today with 55+ tools and
            reports on Pixclick.
          </p>
          <div className="button_forfeatures1 mt-5">
            <button type="button" class="btn  px-3 py-3 fw-bold">
              Get Started For Free
            </button>
          </div>
        </div>
        <div className="col-lg-1 col-md-12 col-12"></div>
        <div className="col-lg-6 col-md-12 col-12 ">
          <img src={FEATURESIMG} className="img-fluid rounded-5 featuresimagessize" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Featuresintro1;
