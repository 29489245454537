import React from "react";
import "./PanelHeader.css";
import { IoHome } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";

const PanelHeader = () => {
  return (
    <div className="Header_body_container">
      <div className="container">
        <div className="row p-2">
          <div className="col-12 p-1 d-flex justify-content-between">
            <NavLink to={`/`}>
              <div className="Header_Icon">
                <IoHome className="HEADER_HOME_ICON" />
              </div>
            </NavLink>

            <div className=" BOOTSTRAP_DROP_DOWN px-3">
              <Form.Select size="sm">
                <option className="p-2">15 Days</option>
                <option>30 Days</option>
                <option>45 Days</option>
              </Form.Select>
            </div>

            {/* <div className="PANEL_HEADER_DROPDOWN px-3">
              <select name="" id="">
                <option value="">15 Days</option>
                <option value="">30 Days</option>
                <option value="">45 Days</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelHeader;
