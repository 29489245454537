import React, { useState } from "react";
import "./OTP_Verify.css";
import "../../../../src/Components/Common.css";
import WebApp_Logo_SideBar from "../../../Assets/WebApp_Logo_SideBar.png";
import { Link, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Advertiser_otp_Api } from "../../../Api/API_List/Advertiser_SignUp_API";
import { isAuthenticated } from "../../../Utils/Auth";

const OTP_Verify = () => {
  // This is for handleValidation
  const location = useLocation();
  const navigate = useNavigate();
  const { email, mobileNumber } = location.state;

  const initialStateInputs = {
    mobileNumber: mobileNumber,
    email: email,
    otp: "",
  };

  // this is for initialStateErrors
  const initialStateErrors = {
    otp: { required: false, valid: false },
    mobileNumber: { required: false, valid: false },
    email: { required: false, valid: false },
  };

  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);

  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (data.otp === "") {
      error.otp.required = true;
    }

    return error;
  };

  // This is for handleInput
  const handleInput = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });

    if (submitted) {
      const newError = handleValidation({ ...inputs, [name]: value });
      setErrors(newError);
    }

    console.log("Console result:", inputs, submitted);
  };

  // This is for handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    console.log("Console result:", inputs);
    setErrors(newError);
    setSubmitted(true);

    // if (handleErrors(newError)) {
    // Advertiser_SignUp_API(inputs)
    // .then((res) => {
    // Assuming form submission is successful without API call for now
    if (inputs.email && inputs.mobileNumber && inputs.otp) {
      const data = {
        email: inputs.email,
        mobileNumber: inputs.mobileNumber,
        otp: inputs.otp,
      };
      Advertiser_otp_Api(inputs)
        .then((res) => {
          if (isAuthenticated()) {
            navigate("/");
            toast.success("OTP verified successfully");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.success("OTP sent to your mobile number");
        });
    } else {
      // If any required input is missing
      toast.error("Please fill in all required fields.");
    }
  };
  // };

  return (
    <div className="OTP_VERIFY_PAGE_BG d-flex align-items-center text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="VERIFY_OTP_LOGO">
              <img
                src={WebApp_Logo_SideBar}
                className="img-fluid "
                alt="WebApp_Logo_SideBar"
              />
            </div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-12 ">
            <div className="VERIFY_CONTENT FS_B">Verify Account</div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-12 ">
            <span className="VERIFY_DETAIL_CONTENT FS_M">
              Enter 4 digit code sent
              <p>
                to your E-mail <span className="fw-bold">{inputs?.email} </span>
                <Link to={"/SignUp_Page_1"}>
                  <span className="VERIFY_NUMBER_CHANGE">Change</span>{" "}
                </Link>
              </p>
            </span>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-12 d-flex flex-row justify-content-center gap-5 FS_B">
            <input
              onChange={handleInput}
              name="otp"
              placeholder="Enter your otp"
            ></input>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-12 text-center">
            <div className="pt-5">
              <NavLink>
                <span className="VERIFY_BUTTON px-5 gap-2">
                  <span className="px-5" onClick={handleSubmit}>
                    Verify OTP
                  </span>
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP_Verify;
