import React from "react";
import "./AboutUs_Main.css";
import BRAND1 from "./AboutAssests/images/Brandrow1.svg";
import BRAND2 from "./AboutAssests/images/Brandrow1-2.svg";
import BRAND3 from "./AboutAssests/images/Brandrow1-3.svg";
import BRAND4 from "./AboutAssests/images/Brandrow1-4.svg";
import BRAND5 from "./AboutAssests/images/Brandrow1-5.svg";
import BRAND6 from "./AboutAssests/images/Brandrow1-6.svg";
import DOWN1 from "./AboutAssests/images/branddown1.svg";
import DOWN2 from "./AboutAssests/images/branddown2.svg";
import DOWN3 from "./AboutAssests/images/branddown3.svg";
import DOWN4 from "./AboutAssests/images/branddown4.svg";
import DOWN5 from "./AboutAssests/images/branddown5.svg";
import DOWN6 from "./AboutAssests/images/branddown6.svg";

const AboutUs_LeadingBrands_03 = () => {
  return (
    <div className="container  mt-3 bg-light p-4 py-5 rounded-5">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <h1 className="text-center fw-bold">
            Trusted by the world’s leading brands
          </h1>
        </div>
      </div>
      {/* Brand Names */}
      <div className="row mt-5 ">
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={BRAND1} className="img-fluid BRANDSIZEADJUST11 " alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={BRAND2} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-2">
          <img src={BRAND3} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={BRAND4} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={BRAND5} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={BRAND6} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={DOWN1} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={DOWN2} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={DOWN3} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 ">
          <img src={DOWN4} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-3 BRANDSIZEADJUST">
          <img src={DOWN5} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
        <div className="col-2 mt-4 BRANDSIZEADJUST">
          <img src={DOWN6} className="img-fluid BRANDSIZEADJUST11" alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs_LeadingBrands_03;
