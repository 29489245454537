import { Advertiser_Create_Selling_Account_EP } from "../EndPoints";
import MainApi from "../MainApi";

export const create_selling_account_api = (data) => {
  return MainApi.post(`${Advertiser_Create_Selling_Account_EP}/`, data);
};

export const get_selling_account_api = (data) => {
  return MainApi.get(`${Advertiser_Create_Selling_Account_EP}/`, data);
};
